// TOAST
export const SHOW_TOAST = "SHOW_TOAST"

// MODALS
export const LOGIN_MODAL = "LOGIN_MODAL";
export const REGISTER_MODAL = "REGISTER_MODAL";
export const FORGOT_MODAL = "FORGOT_MODAL";
export const RESET_PASSWORD_MODAL = "RESET_PASSWORD_MODAL";
export const BOOK_LOTTERY_MODAL = 'BOOK_LOTTERY_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const USERSETTINGS_MODAL = 'USERSETTINGS_MODAL'
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL'
export const MY_BOOKINGS_LOTTERY_MODAL = 'MY_BOOKINGS_LOTTERY_MODAL'
export const MY_DESCRIPTIONS = 'MY_BOOKINGS_LOTTERY_MODAL'

//Auth Actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"
export const USER_DATA = "USER_DATA"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const UPDATE_USER = "UPDATE_USER"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const FORGOT_RESPONSE = "FORGOT_RESPONSE"
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE"

//Lotteries Actions
export const GET_LOTTERIES = "GET_LOTTERIES"

//Articles Actions
export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLE_DETAIL = "GET_ARTICLE_DETAIL"

//Draw Result Actions
export const GET_RESULTS = "GET_RESULTS"

//Contact Form Actions
export const SUBMIT_CONTACT_FORM = "SUBMIT_CONTACT_FORM"

//Bookings Actions
export const CREATE_CUSTOMER_TOKEN = "CREATE_CUSTOMER_TOKEN"
export const MAKE_PAYPAL_PAYMENT = "MAKE_PAYPAL_PAYMENT"
export const CREATE_STRIPE_CARD_TOKEN = "CREATE_STRIPE_CARD_TOKEN"
export const MAKE_STRIPE_PAYMENT = "MAKE_STRIPE_PAYMENT"
export const GET_MY_BOOKINGS = "GET_MY_BOOKINGS"