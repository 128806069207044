import React, { Component } from "react";
import DropIn from "braintree-web-drop-in-react";
import { connect } from "react-redux"
import { makePaypalPayment } from "../actions/bookings"
import toast from "../components/common/toast";

class PaypalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaymentSuccess: false,
            clientToken: this.props.customerToken
        }
    }

    payNow = async () => {
        const { nonce } = await this.instance.requestPaymentMethod();
        if (nonce) {
            this.setState({ isPaymentSuccess: true })
            let data = {
                nounce: nonce,
                amount: this.props.lotteryData.totalPayment,
                currency: this.props.lotteryData.currency,
                data: this.props.lotteryData
            }
            await this.props.makePaypalPayment(data)
        } else {
            this.setState({ isPaymentSuccess: false })
            toast.error("Please Try Again");
        }
    }

    render() {
        if (!this.props.customerToken) {
            return (
                <div>
                    <h5>Loading...</h5>
                </div>
            );
        } else {
            return (
                <div>
                    <DropIn
                        options={{ authorization: this.props.customerToken }}
                        onInstance={(instance) => (this.instance = instance)}
                    />
                    <div className="col text-right p-0">
                        <div className="btn-group">
                            <button
                                type="button"
                                className={"btn btn-secondry"}
                                onClick={() => this.props.changePaymentMode()}>
                                Change Payment Mode
                            </button>&nbsp;
                            <button
                                onClick={this.payNow.bind(this)}
                                disabled={this.state.isPaymentSuccess}
                                style={{
                                    fontWeight: '600',
                                    color: 'white',
                                    backgroundColor: '#7400B8'
                                }}>
                                {this.state.isPaymentSuccess ? "Loading..." : "Enter Card Detail & Pay"}
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    customerToken: state.bookings.customerToken,
    paymentResponse: state.bookings.paymentResponse
})

const mapActionsToProps = dispatch => ({
    makePaypalPayment: (data) => dispatch(makePaypalPayment(data))
})

export default connect(mapStateToProps, mapActionsToProps)(PaypalComponent)