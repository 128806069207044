import {
    CREATE_CUSTOMER_TOKEN,
    MAKE_PAYPAL_PAYMENT,
    CREATE_STRIPE_CARD_TOKEN,
    MAKE_STRIPE_PAYMENT,
    GET_MY_BOOKINGS
} from './types';

import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const createCustomerToken = () => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}bookings/create_customer_token`
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: CREATE_CUSTOMER_TOKEN,
        payload: response
    })
}

export const makePaypalPayment = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}bookings/make_pp_payment`,
        data
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: MAKE_PAYPAL_PAYMENT,
        payload: response
    })
}

export const createStripeCardToken = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}bookings/create_stripe_card_token`,
        data
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: CREATE_STRIPE_CARD_TOKEN,
        payload: response
    })
}

export const makeStripePayment = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}bookings/make_stripe_payment`,
        data
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: MAKE_STRIPE_PAYMENT,
        payload: response
    })
}

export const getMyBookings = () => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}bookings/get_my`,
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: GET_MY_BOOKINGS,
        payload: response
    })
}