import {
    SUBMIT_CONTACT_FORM
} from "../actions/types";

const initialState = {
    status: false,
    message: ""
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SUBMIT_CONTACT_FORM:
            return {
                ...state,
                status: payload.data.status,
                message: payload.data.message
            };
        default:
            return state;
    }
}