import {
    GET_RESULTS
} from "../actions/types";

const initialState = {
    results: null,
    resultCount:0
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_RESULTS:
            return {
                ...state,
                results: payload.data.data,
                resultCount: payload.data.other
            };
        default:
            return state;
    }
}