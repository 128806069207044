import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Typography, Button, TextField } from '@material-ui/core';
import { forgotPassword } from "../actions/auth"
import { showLoginModal, showForgotModal, showResetPasswordModal } from "../actions/modals"
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import ritmuLogoImg from '../resources/images/ritmu-logo.png'
import toast from "../components/common/toast"

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "30%",
        margin: '20vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        display: "flex",
        justifyContent: "center",
        fontSize: '1.2rem',
        alignItems: "center",
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    uploadButton: {
        height: "3rem",
        backgroundColor: "#7400B8",
        color: "white",
        font: 'normal normal medium 1.5rem/1.5rem Poppins',
        marginTop: "2rem",
        borderRadius: "0.5rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    modalbody: {
        padding: '1rem 2rem',
        backgroundColor: "white"
    },
    textField: {
        width: '100%',
    },
    loginFields: {
        marginBottom: "1rem"
    },
    footerContent: {
        textAlign: "center",
        marginTop: "5rem"
    },
    signUpOption: {
        width: "100%",
        color: "#7400B8",
        textAlign: "center",
        marginLeft: "0.4rem",
        cursor: "pointer"
    },
    loginLogo: {
        height: '5rem'
    }
}));

const ForgotModal = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const forgotModalVisible = useSelector(state => state.modals.forgotModalVisible)
    const forgotStatus = useSelector(state => state.auth.forgotStatus)
    
    const handleForgot = async () => {
        if (email === "") {
            toast.error("Please fill your registered email address to proceed...")
            return false
        }
        setLoading(true);
        dispatch(forgotPassword({ email }))
    }

    const userLogin = () => {
        dispatch(showForgotModal(false))
        dispatch(showLoginModal(true))
    }
    
    const alreadyOtp = () => {
        dispatch(showForgotModal(false))
        dispatch(showResetPasswordModal(true))
    }

    useEffect(() => {
        if(forgotStatus != null){
            setLoading(false);
            console.log("forgot statue ----->>>>>")
            if(!forgotStatus){
                toast.error("Email is Not Registered with Us...")
                return false
            } 
            dispatch(showForgotModal(false))
            dispatch(showResetPasswordModal(true))
        }
    },[forgotStatus])

    return (
        <Modal
            open={forgotModalVisible}
            onClose={() => dispatch(showForgotModal(false))}
            className={classes.paper}>
            <Box>
                <Box className={classes.modalbody}>
                    <Box style={{ display: 'flex', justifyContent: "center", cursor: "pointer" }} >
                        <img src={ritmuLogoImg} className={classes.loginLogo} />
                    </Box>
                    <Typography className={classes.loginTitle}>Reset Your Account Password</Typography>
                    <Box className={classes.loginFields}>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="Registered Email Address"
                            type="text"
                            variant="outlined"
                            placeholder={"abc@xyz.com"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                    <Button
                        variant="raised"
                        component="span"
                        className={classes.uploadButton}
                        style={{ width: "100%" }}
                        onClick={() => handleForgot()}
                        disabled={loading ? true : false}>
                        {loading ? <CircularProgress /> : "Send Password Instructions"}
                    </Button>
                    <Box style={{marginTop:'1rem'}}>
                        <Typography>Have Password ? <span className={classes.signUpOption} onClick={userLogin}>Sign In</span></Typography>
                    </Box>
                    <Box className={classes.footerContent}>
                        <Typography>Already Have OTP ? <span className={classes.signUpOption} onClick={alreadyOtp}>Change Password</span></Typography>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
export default ForgotModal