import React, { useState, useEffect } from "react";
import Cards from 'react-credit-cards';
import './style.scss';
import { useDispatch, useSelector } from "react-redux"
import toast from "../components/common/toast"

import { createStripeCardToken, makeStripePayment } from "../actions/bookings"

const SplitForm = ({
    lotteryData,
    changePaymentMode
}) => {
    const [cvc, setCVC] = useState('');
    const [expiryM, setExpiryM] = useState('');
    const [expiryY, setExpiryY] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [focused, setFocused] = useState('');

    const dispatch = useDispatch()

    const stripeCardToken = useSelector(state => state.bookings.stripeCardToken)

    useEffect(() => {
        if (stripeCardToken) {
            if (!stripeCardToken.status) {
                toast.error("Payment Error !!! Please try again after some time")
                return false
            }
            let data = {
                currency: lotteryData.currency,
                token: stripeCardToken.data.id,
                amount: lotteryData.totalPayment,
                lotteryData
            }
            dispatch({
                type: 'CREATE_STRIPE_CARD_TOKEN',
                payload: {data:null}
            })
            dispatch(makeStripePayment(data))
        }
    }, [stripeCardToken])

    const handlePayNowButton = async () => {
        if (number == "" || cvc == "" || expiryM == "" || expiryY === "") {
            toast.error("Please Enter Valid Card Detail....");
            return false;
        }
        let exp_month = expiryM;
        let exp_year = expiryY;
        let data = {
            number, cvc, exp_month, exp_year
        }
        setNumber('')
        setCVC('')
        setExpiryM('')
        setExpiryY('')
        dispatch(createStripeCardToken(data))
    }

    return (
        <div id="PaymentForm">
            <Cards
                cvc={cvc}
                expiryM={expiryM}
                expiryY={expiryY}
                name={name}
                number={number}
                focused={focused}
            />
            <form className={"mt-3"}>
                <div className="row">
                    <div className="col-12 form-group">
                        <label>Card Number</label>
                        <input
                            type="tel"
                            name="number"
                            maxLength={16}
                            className={"form-control"}
                            placeholder="Card Number"
                            onChange={(e) => setNumber(e.target.value)}
                            onFocus={(e) => setFocused(e.target.name)}
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label>Month</label>
                        <input
                            type="tel"
                            name="number"
                            maxLength={2}
                            className={"form-control"}
                            placeholder="Month"
                            onChange={(e) => setExpiryM(e.target.value)}
                            onFocus={(e) => setFocused(e.target.name)}
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label>Year</label>
                        <input
                            type="tel"
                            name="number"
                            maxLength={2}
                            className={"form-control"}
                            placeholder="Year"
                            onChange={(e) => setExpiryY(e.target.value)}
                            onFocus={(e) => setFocused(e.target.name)}
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label>CVC / CVV</label>
                        <input
                            type="tel"
                            name="number"
                            placeholder="CVC"
                            className={"form-control"}
                            maxLength={3}
                            onChange={(e) => setCVC(e.target.value)}
                            onFocus={(e) => setFocused(e.target.name)}
                        />
                    </div>
                    <div className="col-12 form-group">
                        <label>Card Holder Name</label>
                        <input
                            type="tel"
                            name="number"
                            className={"form-control"}
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            onFocus={(e) => setFocused(e.target.name)}
                        />
                    </div>
                    <div className="col-12 text-right">
                        <button
                            type="button"
                            className={"btn btn-secondry"}
                            onClick={() => changePaymentMode()}>
                            Change Payment Mode
                        </button>&nbsp;
                        <button
                            type="button"
                            className={"btn btn-primary"}
                            onClick={() => handlePayNowButton()}>
                            Pay Now
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SplitForm;
