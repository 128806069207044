import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Modal } from '@material-ui/core';
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"

import { showMyBookingsModal } from "../actions/modals"

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "70%",
        margin: '4vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    lotteryDetaiils: {
        backgroundColor: "white",
        padding: "2rem 3rem",
        borderRight: "1px solid black"
    }
}));

const MyBookingsModal = () => {
    const classes = useStyles();
    
    const dispatch = useDispatch()

    const myBookings = useSelector(state => state.bookings.myBookings)
    const myBookingsModalVisible = useSelector(state => state.modals.myBookingsModalVisible)

    useEffect(() => {
        // console.log(myBookings)
    }, [myBookings])

    return (
        <Modal
            open={myBookingsModalVisible}
            onClose={() => dispatch(showMyBookingsModal({ show: false, bookings_data: null }))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.paper}
        >
            <Grid className={"container-fluid"} style={{marginTop:'50px', overflow:'scroll'}}>
                <Grid item xs={12} sm={12} md={12} className={classes.lotteryDetaiils} style={{overflow:'scroll', height:'70vh'}}>
                    <h4 style={{color:'#511F70'}}>My Bookings</h4>
                    <table style={{fontSize:'14px', overflow:'scroll'}} className="table table-bordered">
                        <tr>
                            <th>S.No</th>
                            <th>Lottery Name</th>
                            <th>Lottery Date</th>
                            <th>Time Slot</th>
                            <th>Booked Number</th>
                            <th>Amount</th>
                            <th>Amount Chance 2</th>
                            <th>Payment Mode</th>
                            <th>Booked On</th>
                            <th>Winning Status</th>
                        </tr>
                        {myBookings.map((booking, index) => (
                            <tr>
                                <td>{(index+1)}</td>
                                <td>{booking.lottery_id.lottery_name}</td>
                                <td>{booking.lottery_date}</td>
                                <td>{booking.lottery_time}</td>
                                <td>{booking.lottery_number}</td>
                                <td>{booking.amount}</td>
                                <td>{booking.amount_chance_2}</td>
                                <td>{booking.payment_mode}</td>
                                <td>{moment(booking.booked_on*1000).format('LL')}</td>
                                <td>{booking.is_won==1?"Won":booking.is_won==2?"Lost":"Result Awaited"}</td>
                            </tr>
                        ))}
                    </table>
                    <label style={{fontSize:'12px', color:'#666'}}>if you didn't get amount your winning amount (In case of win) then Please feel free write us at support@ritmu.tv</label>        
                </Grid>
            </Grid>
        </Modal>
    );
}

export default MyBookingsModal