import {
    GET_ARTICLES,
    GET_ARTICLE_DETAIL    
} from './types';
import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const getArticles = () => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}blogs/get_all`
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: GET_ARTICLES,
        payload: response
    })
}
export const getArticleDetail = (url) => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}blogs/get`,
        data: {url}
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: GET_ARTICLE_DETAIL,
        payload: response
    })
}