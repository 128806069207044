import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import {
    Box,
    Modal,
    Typography,
    Button,
    TextField,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl
} from '@material-ui/core';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ritmuLogoImg from '../resources/images/ritmu-logo.png'
import { registerUser } from "../actions/auth"
import { showLoginModal, showRegisterModal } from "../actions/modals"
import CircularProgress from '@material-ui/core/CircularProgress';
import toast from "../components/common/toast"

var cc = require('currency-codes');
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "30%",
        margin: '4vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        fontSize: "1.2rem",
        fontWeight: "500",
        marginTop: "10px"
    },
    uploadButton: {
        height: "3rem",
        backgroundColor: "#7400B8",
        color: "white",
        font: 'normal normal medium 1.5rem/1.5rem Poppins',
        marginTop: "2rem",
        borderRadius: "0.7rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    modalbody: {
        padding: '1rem 2rem',
        backgroundColor: "white"
    },
    textField: {
        width: "48%",
    },
    loginFields: {
        marginBottom: "0.5rem"
    },
    footerContent: {
        textAlign: "center",
        marginTop: "2rem"
    },
    signUpOption: {
        width: "100%",
        color: "#7400B8",
        textAlign: "center",
        marginLeft: "0.4rem",
        cursor: "pointer"
    },
    createNewPaypal: {
        width: '100%',
        textAlign: 'end',
        fontSize: '0.625rem',
        lineHeight: 0,
        cursor: 'pointer',
        color: '#7400B8',
    },
    loginLogo: {
        height: '5rem'
    },
    fname: {
        width: "48%"
    },
}));

const RegisterModal = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [country, setCountry] = useState('')
    const [currency, setCurrency] = useState('')
    const [paypalEmail, setPaypalEmail] = useState('')
    const [currencyCodes, setCurrencyCodes] = useState([])
    const countryOptions = useMemo(() => countryList().getData(), [])

    const dispatch = useDispatch()

    const registerModalVisible = useSelector(state => state.modals.registerModalVisible)

    useEffect(() => {
        let tmpArray = [];
        cc.codes().map((opt) => {
            tmpArray.push({ value: opt, label: opt })
        })
        setCurrencyCodes(tmpArray)
    }, [cc])

    const handleSubmit = async() => {
        if (fname === "" || email === "" || password === "" || country === "" || currency === "" || paypalEmail === "") {
            toast.error("Please fill all the required fields to continue")
            return false
        }
        if(password !== confPassword){
            toast.error("Password and Confirm Password Should Be Same")
            return false
        }
        setLoading(true)
        let userData = {
            fname,
            lname,
            email,
            password,
            country,
            currency,
            paypalEmail
        }
        let response = await dispatch(registerUser(userData));
        if(!response.status){
            setLoading(false)
            toast.error(response.message)
            return false
        }
        setLoading(false)
        toast.success(response.message)
        dispatch(showRegisterModal(false))
    }

    const alreadyLogin = () => {
        dispatch(showRegisterModal(false))
        dispatch(showLoginModal(true))
    }

    return (
        <Modal
            open={registerModalVisible}
            onClose={() => dispatch(showRegisterModal(false))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.paper}
        >
            <Box>
                <Box className={classes.modalbody}>
                    <Box style={{ display: 'flex', justifyContent: "center", cursor: "pointer" }} >
                        <img src={ritmuLogoImg} className={classes.loginLogo} />
                    </Box>
                    <Typography className={`${classes.loginTitle}`}>Register New Account</Typography>
                    <Box className={classes.loginFields}>
                        <TextField
                            id="outlined-password-input"
                            label="First Name"
                            type="text"
                            variant="outlined"
                            value={fname}
                            className={classes.fname}
                            style={{ marginRight: "2%" }}
                            onChange={(txt) => setFname(txt.target.value)}
                        />
                        <TextField
                            id="outlined-password-input"
                            label="Last Name"
                            type="text"
                            value={lname}
                            className={classes.fname}
                            variant="outlined"
                            style={{ marginLeft: "2%" }}
                            onChange={(txt) => setLname(txt.target.value)}
                        />
                    </Box>
                    <Box className={classes.loginFields}>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={email}
                            onChange={(txt) => setEmail(txt.target.value)}
                        />
                    </Box>
                    <Box className={classes.loginFields}>
                        <FormControl
                            style={{ marginRight: "2%" }}
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(txt) => setPassword(txt.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        <FormControl
                            style={{ marginLeft: "2%" }}
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'password'}
                                value={confPassword}
                                onChange={(txt) => setConfPassword(txt.target.value)}
                                labelWidth={70}
                            />
                        </FormControl>
                    </Box>
                    <Box className={classes.loginFields}>
                        <Select options={countryOptions} value={country} onChange={(value) => setCountry(value)} placeholder='Select Country' />
                    </Box>
                    <Box className={classes.loginFields}>
                        <Select options={currencyCodes} value={currency} onChange={(value) => setCurrency(value)} placeholder='Select Currency' />
                    </Box>
                    <Box className={classes.loginFields}>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="Paypal Account"
                            type="email"
                            variant="outlined"
                            value={paypalEmail}
                            onChange={(txt) => setPaypalEmail(txt.target.value)}
                        />
                    </Box>
                    <Typography className={classes.createNewPaypal}>Create New Paypal Account</Typography>
                    <Button
                        variant="raised"
                        component="span"
                        className={classes.uploadButton}
                        style={{ color:"white", width: "100%" }}
                        onClick={() => handleSubmit()}
                        disabled={loading?true:false}
                    >
                        {loading?<CircularProgress style={{color: "white!important"}} size={25} /> : 'Register Now'}
                    </Button>
                    <Box className={classes.footerContent}>
                        <Typography>Already a member? <span className={classes.signUpOption} onClick={alreadyLogin}>Log In</span></Typography>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default RegisterModal