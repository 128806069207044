import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import { Box, Modal, Typography, Button, TextField } from '@material-ui/core';
import ritmuLogoImg from '../resources/images/ritmu-logo.png'
import { changePassword } from "../actions/auth"
import { showChangePasswordModal } from "../actions/modals"
import CircularProgress from '@material-ui/core/CircularProgress';
import toast from "../components/common/toast"

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "30%",
        margin: '4vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        fontSize: "1.2rem",
        fontWeight: "500",
        marginTop: "10px"
    },
    uploadButton: {
        height: "3rem",
        backgroundColor: "#7400B8",
        color: "white",
        font: 'normal normal medium 1.5rem/1.5rem Poppins',
        marginTop: "2rem",
        borderRadius: "0.7rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    modalbody: {
        padding: '1rem 2rem',
        backgroundColor: "white"
    },
    textField: {
        width: "48%",
    },
    loginFields: {
        marginBottom: "0.5rem"
    },
    loginLogo: {
        height: '5rem'
    },
    fname: {
        width: "48%"
    },
}));

const ChangePasswordModal = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [old_password, setOldPassword] = useState('')
    const [new_password, setNewPassword] = useState('')
    const [conf_password, setConfPassword] = useState('')

    const dispatch = useDispatch()
    
    const changePasswordModalVisible = useSelector((state) => state.modals.changePasswordModalVisible)
        
    const handleSubmit = async () => {
        if (old_password === "" || new_password === "" || conf_password === "") {
            toast.error("Please fill all the required fields to continue")
            return false
        }
        if(new_password !== conf_password) {
            toast.error("Password and Confirm Password Should be Same...")
            return false
        }
        setLoading(true)
        let userData = {
            old_password,
            new_password
        }
        dispatch(changePassword(userData));
        setLoading(false)
        dispatch(showChangePasswordModal(false))
    }

    return (
        <Modal
            open={changePasswordModalVisible}
            onClose={() => dispatch(showChangePasswordModal(false))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.paper}
        >
            <Box>
                <Box className={classes.modalbody}>
                    <Box style={{ display: 'flex', justifyContent: "center", cursor: "pointer" }} >
                        <img alt="" src={ritmuLogoImg} className={classes.loginLogo} />
                    </Box>
                    <Typography className={`${classes.loginTitle}`}>Change Your Password</Typography>
                    <Box className={classes.loginFields}>
                        <TextField
                            id="outlined-password-input"
                            label="Old Password"
                            type="password"
                            value={old_password}
                            className={classes.fname}
                            variant="outlined"
                            style={{ width:'100%' }}
                            onChange={(txt) => setOldPassword(txt.target.value)}
                        />
                    </Box>
                    <Box className={classes.loginFields} style={{marginTop:"2rem"}}>
                        <TextField
                            id="outlined-password-input"
                            label="New Password"
                            type="password"
                            variant="outlined"
                            value={new_password}
                            className={classes.fname}
                            style={{ marginRight: "2%" }}
                            onChange={(txt) => setNewPassword(txt.target.value)}
                        />
                        <TextField
                            id="outlined-password-input"
                            label="Confirm Password"
                            type="password"
                            value={conf_password}
                            className={classes.fname}
                            variant="outlined"
                            style={{ marginLeft: "2%" }}
                            onChange={(txt) => setConfPassword(txt.target.value)}
                        />
                    </Box>
                    <Button
                        variant="raised"
                        component="span"
                        className={classes.uploadButton}
                        style={{ color: "white", width: "100%" }}
                        onClick={() => handleSubmit()}
                        disabled={loading ? true : false}
                    >
                        {loading ? <CircularProgress style={{ color: "white!important" }} size={25} /> : 'Update Password'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default ChangePasswordModal