import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    navBarContainer: {
        height: "3rem",
        display: "flex",
        alignItems: "center",
    },
    navBarContainerForDesktopView: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    navBarContainerForMobileView: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: "block"
        }
    },
    navBarItem: {
        color: "#7400B8",
        fontWeight: 600,
        cursor: "pointer"
    },
    menuIcon: {
        height: "1.5em",
        width: "2.5em",
        marginTop: '5px'
    },
    navBarItemMobile: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        cursor: "pointer",
        height: '2rem',
        backgroundColor: "#511e70",
        borderTop: "1px solid blueviolet",
    }
}));

const TopNavBar = (action) => {

    const classes = useStyles();
    const history = useHistory()
    const [dropDown, setDropDown] = useState(false)

    const showDropDownForNavItems = () => {
        setDropDown(!dropDown)
    }

    return (
        <>
            <Box className={classes.navBarContainer}>
                <Box className={classes.navBarContainerForDesktopView}>
                    <Typography onClick={() => action.scrollTo('home')} className={classes.navBarItem}> Home </Typography>
                    <Typography onClick={() => action.scrollTo('play_online')} className={classes.navBarItem}> Play Online </Typography>
                    <Typography onClick={() => action.scrollTo('how_to')} className={classes.navBarItem}> How To play </Typography>
                    <Typography onClick={() => action.scrollTo('draw_result')} className={classes.navBarItem}> Draw Result </Typography>
                    <Typography onClick={() => action.scrollTo('live_tv')} className={classes.navBarItem}> Live TV </Typography>
                    <Typography onClick={() => action.scrollTo('contact_us')} className={classes.navBarItem}> Contact us </Typography>
                    <Typography onClick={() => action.scrollTo('blog')} className={classes.navBarItem}> Blog </Typography>
                </Box>
                <Box className={classes.navBarContainerForMobileView}>
                    <MenuIcon className={classes.menuIcon} onClick={() => showDropDownForNavItems()} />
                </Box>
            </Box>
            {dropDown ?
                <>
                    <Box>
                        <Typography className={classes.navBarItemMobile}> Home </Typography>
                        <Typography className={classes.navBarItemMobile}> Play Online </Typography>
                        <Typography className={classes.navBarItemMobile}> How To play </Typography>
                        <Typography className={classes.navBarItemMobile}> Draw Result </Typography>
                        <Typography className={classes.navBarItemMobile}> Live TV </Typography>
                        <Typography className={classes.navBarItemMobile}> Contact us </Typography>
                        <Typography className={classes.navBarItemMobile}> Blog </Typography>
                    </Box>
                </> : null}
        </>
    )
}

export default TopNavBar;