
import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArticleCard from '../../components/articleCard'

import { getArticles } from "../../actions/articles"
import { useSelector, useDispatch } from "react-redux"

import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]: {
            // paddingLeft: "0.2rem",
        },
    },
    descriptionTitle: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "1rem"
    },
    firstHead: {
        fontWeight: 600,
        color: "#7400B8",
        fontSize: 28,
        marginRight: "0.5rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    secondHead: {
        fontWeight: 600,
        fontSize: 28,
        borderBottom: "1px solid #7400B8",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    featuredArticle: {
        display: "flex",
        flexWrap: "wrap"
    }
}))

const FeaturedArticle = ({name}) => {
    
    const articles = useSelector((state) => state.articles.articles)
    const classes = useStyles();
    const dispatch = useDispatch()

    const [swipper, setSwipper] = useState(null)

    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    useEffect(()=>{
        loadArticles()
    },[])
   
    const loadArticles = () => {
        dispatch(getArticles())
    }
    
    SwiperCore.use([Autoplay, Navigation]);

    return (
        <Box className={classes.root}>
            <Box className={classes.descriptionTitle}>
                <Typography className={classes.firstHead}>{name}</Typography>
                <Typography className={classes.secondHead}>Articles</Typography>
            </Box>
            {articles.status ? 
            <Swiper
                spaceBetween={25}
                slidesPerView={dimensions.width<600?1:dimensions.width<900?2:dimensions.width<1100?3:4}
                onSwiper={(swiper) => {
                    setSwipper(swiper)
                }}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                onInit={(swiper) => {
                    swiper.autoplay.start()
                }}
                style={{ padding: "3rem", backgroundColor: "#511e70" }}>
                {articles.data && articles.data.map((article) => (
                    <SwiperSlide
                        onMouseEnter={() => {
                            swipper.autoplay.stop()
                        }}
                        onMouseLeave={() => {
                            swipper.autoplay.start()
                        }}>
                        <ArticleCard data={article} />
                    </SwiperSlide>
                ))}
            </Swiper>
            : null }
        </Box >
    )
}

export default FeaturedArticle;