import { createMuiTheme } from '@material-ui/core/styles';

const theme=createMuiTheme({
    palette:{
        primary:{
            main:"#00264E"
        },
        secondary:{
            main:"#FF5E6F"
        },
        danger:{
            main:"#d9534f"
        }
    },
    typography: {
        subtitle1: {
          fontSize: 12
        },
        field:{
            fonSize:14,
            fontWeight:500
        }
      },
})

export default theme