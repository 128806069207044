import React from 'react'
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Countdown from "react-countdown"

const renderer = ({ hours, minutes, seconds }) => {
    return (
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box style={{ padding: "0 0.4rem" }}>
                <Typography style={{ fontWeight: 600, fontSize: "1rem" }}>{hours <= 9 ? '0' + hours : hours}</Typography>
                <Typography style={{ fontSize: "0.8rem", fontWeight: 500 }}>Hr</Typography>
            </Box>
            <Typography style={{ marginTop: "-1.5rem", fontWeight: 600, marginRight: '8px' }}>{' : '}</Typography>
            <Box>
                <Typography style={{ fontWeight: 600, fontSize: "1rem" }}>{minutes <= 9 ? '0' + minutes : minutes}</Typography>
                <Typography style={{ fontSize: "0.8rem", fontWeight: 500 }}>Mins</Typography>
            </Box>
            <Typography style={{ marginTop: "-1.5rem", fontWeight: 600, marginRight: '8px' }}>{' : '}</Typography>
            <Box>
                <Typography style={{ fontWeight: 600, fontSize: "1rem" }}>{seconds <= 9 ? '0' + seconds : seconds}</Typography>
                <Typography style={{ fontSize: "0.8rem", fontWeight: 500 }}>Secs</Typography>
            </Box>
        </Box>
    )
};

const TimerCont = (data) => {
    let { timmings } = data
    return (
        <Countdown
            date={new Date(timmings * 1000)}
            renderer={renderer}
        />
    )
}

export default TimerCont;