import {
    GET_RESULTS
} from './types';
import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const getResults = (data) => async dispatch => {
    return new Promise( async (resolve, reject) => {
        let config = {
            method: 'post',
            headers: {},
            url: `${BASE_URL}draws/get_by_date`,
            data,
        }
        let response = await apiCall(config, dispatch)
        dispatch({
            type: GET_RESULTS,
            payload: response
        })
        resolve(response.data);
    })
}