import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ModalConnecter from "./common/ModalConnecter";
import DashBoard from './common/dashboard';
import NotFound from './common/not_found';
import Blogs from './common/featuredArticles';
import BlogDetail from './common/blog_detail';
import Header from './common/header';

const useStyles = makeStyles((theme) => ({
    superRoot: {
        display: "flex"
    },
    root: {
        width: "100%",
        backgroundColor: "#F9F2FF",
    },
}));

const Main = () => {
    
    const classes = useStyles();
    
    return (
        <Router>
            <Box>
                <ModalConnecter />
                <Header />
                <Grid className={classes.superRoot}>
                    <Grid item xs={12} sm={12} md={12} className={classes.root}>
                        <Router>
                            <Switch>
                                <Route path="/" exact component={DashBoard} />
                                <Route path="/blog" exact component={Blogs} />
                                <Route path="/blog/:blog_url" exact component={BlogDetail} />
                                <Route path="*" component={NotFound} />
                            </Switch>
                        </Router>
                    </Grid>
                </Grid>
            </Box>
        </Router>
    );
}

export default Main;