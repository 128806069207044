import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { IMG_URL } from "../../const"

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import logo from "../../resources/images/ritmu-logo.png"

import TopNavBar from './navBar2'
import Footer from './footer2'
import FeaturedArticle from './featuredArticles'

import { getArticleDetail } from "../../actions/articles"

const BlogDetail = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        getBlogData();
    }, [])

    const getBlogData = async () => {
        const { blog_url } = props.match.params
        dispatch(getArticleDetail(blog_url));
    }

    const blogResponse = useSelector(state => state.articles.articleData)

    return (
        <>
            <TopNavBar />
            {(blogResponse && blogResponse.status) ?
                <>
                <div className={"container"}>
                    <Typography style={{ margin: "2rem 0", textAlign: "center", fontSize: "2.5rem" }}>Article Detail </Typography>
                    <Typography style={{ margin: "2rem 0", textAlign: "center", fontSize: "1.5rem" }}>{blogResponse.data.title}</Typography>
                    <img src={IMG_URL + blogResponse.data.image} className="img-thumbnail" />
                    <Typography style={{ margin: "2rem 0", textAlign: "left", fontSize: "1.2rem" }}>{blogResponse.data.short_desc}</Typography>
                    <div style={{ margin: "2rem 0", textAlign: "left", fontSize: "1rem" }}>{blogResponse.data.description}</div>
                </div>
                <Box >
                    <FeaturedArticle name="Related" />
                </Box>
                </>
                :
                <>
                    <Box style={{ justifyContent: 'center', background: 'white', alignItems: 'center', display: 'flex' }}>
                        <img src={logo} style={{ height: "12rem", marginTop: "5rem" }} />
                    </Box>
                    <Box style={{ justifyContent: 'center', background: 'white', alignItems: 'center', display: 'flex' }}>
                        <Typography style={{ marginTop: "3rem", fontSize: "1.5rem" }}>BlogDetail page</Typography>
                    </Box>
                    <Box style={{ justifyContent: 'center', background: 'white', alignItems: 'center', display: 'flex' }}>
                        <a
                            onClick={() => history.push("/")}
                            style={{ marginTop: "1rem", fontSize: "0.9rem", textDecoration: "underline", cursor: "pointer", color: "blueviolet" }}>Go Back to Main Page
                        </a>
                    </Box>
                </>
            }
            <Footer />
        </>
    );
}

export default BlogDetail;