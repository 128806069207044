import React from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import logo from "../../resources/images/ritmu-logo.png"

const NotFound = () => {

    const history = useHistory()
    
    return (
        <>
            <Box style={{justifyContent:'center', background:'white', alignItems: 'center', display: 'flex'}}>
                <img src={logo} style={{height:"12rem", marginTop:"5rem" }} />
            </Box>
            <Box style={{justifyContent:'center', background:'white', alignItems: 'center', display: 'flex'}}>
                <Typography style={{marginTop:"3rem", fontSize: "1.5rem" }}>Page Not Found</Typography>
            </Box>
            <Box style={{justifyContent:'center', background:'white', alignItems: 'center', display: 'flex'}}>
                <a 
                    onClick={() => history.push("/")}
                    style={{marginTop:"1rem", fontSize: "0.9rem", textDecoration: "underline", cursor:"pointer", color:"blueviolet" }}>Go Back to Main Page</a>
            </Box>
        </>
    );
}

export default NotFound;