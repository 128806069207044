import 'date-fns';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import ritmuLogoImg from '../../resources/images/ritmu-logo.png'
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import toast from '../../components/common/toast';
import { getResults } from "../../actions/draw_results"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center"
    },
    drawResultContainer: {
        width: "100%",
        padding: "3rem 0",
        backgroundColor: "#511e70",
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    logoIcons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        marginLeft: "2rem"
    },
    profilePicture: {
        height: '5rem',
        width: "5rem",
        borderRadius: '50px',
        overflow: "hidden"
    },
    drawTitle: {
        fontSize: '2rem',
        color: "white",
        fontWeight: 600
    },
    loginButton: {
        backgroundColor: "#7400B8",
        color: "white",
        width: "15rem",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    resultFields: {
        backgroundColor: "white",
        padding: 5,
        borderRadius: "5px",
        width:'20%',
        float: 'left',
        marginLeft: '10%',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            width:'29%',
            float: 'left',
            marginLeft: '3%',
            marginTop: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            width:'60%',
            marginLeft: '20%',
            marginTop: '10px',
        }
    },

    drawResult: {
        width: "100%",
        backgroundColor: "#511e70",
        textAlign: 'center'
    },
    resultBox: {
        width: '60%',
        marginLeft: '20%',
        borderRadius: '5px',
        paddingBottom: "50px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginLeft: '0%',
        }
    }
}))

const DrawResult = (data) => {
    const classes = useStyles();
    const [lottery, setLottery] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [page, setPage] = useState(0);
    const [isResult, setIsResult] = useState(false);
    const rowsPerPage = 50;
    const lotteries = data.data
    const drawResults = useSelector((state) => state.draw_results.results)
    const resCount = useSelector((state) => state.draw_results.resultCount?state.draw_results.resultCount:0)

    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        let data = {
            page: newPage.toString(),
            lottery_id: lottery,
            start_date: startDate,
            end_date: endDate
        }
        setIsResult(true)
        dispatch(getResults(data))
    };

    const selectDate = (e, type) => {
        if (type == "start") {
            setStartDate(e)
        } else if (type == 'end') {
            setEndDate(e)
        }
    }

    useEffect(() => {
    }, [drawResults])

    const showResults = () => {
        if (lottery == "" || startDate == "" || endDate == "") {
            toast.error("Please Select Lottery Name and Dates of Result")
            return false
        }
        let data = {
            page: page.toString(),
            lottery_id: lottery,
            start_date: startDate,
            end_date: endDate
        }
        setIsResult(true)
        dispatch(getResults(data))
    }

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.drawResultContainer}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography className={classes.drawTitle}>DRAW RESULT</Typography>
                        <Box className={classes.logoIcons} style={{ justifyContent: "normal", cursor: "pointer" }} >
                            <img src={ritmuLogoImg} className={classes.profilePicture} />
                        </Box>
                    </Box>
                    <Box style={{marginTop: "2rem" }}>
                        <Box className={classes.resultFields}>
                            <Typography style={{ fontWeight: 600, fontSize: 12, color: '#666' }}>Select Lottery</Typography>
                            <select className={'form-control'} onChange={(e) => setLottery(e.target.value)} placeholder='Select Lottery' >
                                <option value={""}>Select Lottery</option>
                                {lotteries.map((lottery) => (
                                    <option value={lottery._id}>{lottery.lottery_name}</option>
                                ))}
                            </select>
                        </Box>
                        <Box className={classes.resultFields}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    format="dd-MMM-yyyy"
                                    label={(<Typography style={{ fontWeight: 600 }}>From</Typography>)}
                                    value={startDate}
                                    onChange={(e) => selectDate(e, 'start')}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box className={classes.resultFields}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    format="dd-MMM-yyyy"
                                    label={(<Typography style={{ fontWeight: 600 }}>To</Typography>)}
                                    value={endDate}
                                    minDate={new Date(startDate)}
                                    onChange={(e) => selectDate(e, 'end')}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Box>
                    <div className={'clearfix'}></div>
                    <div style={{width:'100%', textAlign:"center", marginTop: "2rem" }}>
                        <Button className={classes.loginButton} onClick={() => showResults()}>Show Results</Button>
                    </div>
                </Box>
            </Box>
            {isResult &&
                <>
                    <Box className={classes.drawResult}>
                        {drawResults &&
                            <Box className={classes.resultBox}>
                                <table className={'table bg-white'}>
                                    <tr>
                                        <th>Time</th>
                                        <th>Result</th>
                                    </tr>
                                    {drawResults.map((result) => (
                                        <>
                                            <tr>
                                                <td className={"font-weight-bold"} colSpan="2">{moment(result.date * 1000).format("LL")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <table className="table table-hover table-condensed">
                                                        {result.data.map((value) => (
                                                            <tr>
                                                                <td className={"font-weight-bold"}>{value.time_slot}</td>
                                                                <td>
                                                                    {value.lottery_id.lottery_name}
                                                                    {value.lottery_id.secondNumber ? " 1st (" : " ("}
                                                                    {value.first_number} )
                                                        {value.lottery_id.secondNumber ? " 2nd (" + value.s1 + ")*(" + value.s2 + ")*(" + value.s3 + ")" : ""}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </table>
                            </Box>
                        }
                        {!drawResults &&
                            <p style={{ color: 'aliceblue', fontWeight: 500, padding: "2.5rem 0" }}>No Data Found</p>
                        }
                        {console.log("eeeeeeeeee", drawResults)}
                        <TablePagination
                            component="div"
                            count={resCount}
                            style={{ color: "white", marginRight: '10rem' }}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={[50, '']}
                            rowsPerPage={rowsPerPage}
                        />
                    </Box>
                </>
            }
        </>
    )
}

export default DrawResult