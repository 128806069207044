import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from '../../components/common/toast'
import { submitContactForm } from "../../actions/contactus"


const ContactForm = () => {

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const isContactDataSaved = useSelector((state) => state.contactus.status)
    const submitMessage = useSelector((state) => state.contactus.message)

    const dispatch = useDispatch()

    const handleContactForm = () => {
        if (name === "" || email === "" || message === "" || country === "" || phone === "") {
            toast.error("Please Fill all the Feilds to Proceed Further....")
            return false
        }
        let data = {
            name, email, country, phone, message
        }
        dispatch(submitContactForm(data))
    }

    return (
        <section style={{ padding: '4rem' }}>
            <h2 className="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
            <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                a matter of hours to help you.</p>
            <div className={"row pt-5"}>
                {(!isContactDataSaved) &&
                    <div className="col-md-10 col-lg-6 col-sm-12 col-xs-12 offset-md-1 offset-lg-3 mb-3">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <label for="name" className="">Your name</label>
                                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control p-3" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <label for="email" className="">Your email</label>
                                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control p-3" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <label for="subject" className="">Country</label>
                                    <input type="text" onChange={(e) => setCountry(e.target.value)} value={country} className="form-control p-3" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="md-form mb-0">
                                    <label for="subject" className="">Phone</label>
                                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone} className="form-control p-3" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="md-form">
                                    <label for="message">Your message</label>
                                    <textarea type="text" onChange={(e) => setMessage(e.target.value)} value={message} rows="5" className="form-control md-textarea"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-3">
                            <a className="btn btn-primary" style={{ padding: "0.5rem 5rem", color: "white" }} onClick={() => handleContactForm()}>Submit Query</a>
                        </div>
                        <div className="status"></div>
                    </div>
                }
                {isContactDataSaved &&
                    <div className="col-md-10 col-lg-6 col-sm-12 col-xs-12 offset-md-1 offset-lg-3 mb-3">
                        <div className="row">
                            <div className="col-md-12 text-center mt-5 text-success font-weight-bold">
                                Your Contact Request has been Submitted Successfully and <br />our Executive will get back to you shortly
                            </div>
                        </div>
                    </div>
                }
                <div className="col-lg-3 col-12 text-center">
                    <ul className="list-unstyled mb-0" style={{ marginTop: "3rem" }}>
                        <li><i className="fas fa-map-marker-alt fa-2x"></i>
                            <p>Fahaheel, P O Box: 771 Hadiya, Kuwait City</p>
                        </li>
                        <li><i className="fas fa-phone mt-4 fa-2x"></i>
                            <p>+965 239 114 01</p>
                        </li>
                        <li><i className="fas fa-envelope mt-4 fa-2x"></i>
                            <p>contact@ritmu.tv</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default ContactForm;