import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import paypalImg from '../../resources/images/paypal.png'
import stripeImg from '../../resources/images/stripe.png'

const useStyles = makeStyles((theme) => ({
    paymentImages: {
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            width: "10rem"
        }
    }
}));

const PaymentMethods = () => {
    const classes = useStyles();
    const [loader, setLoader] = useState(false)
    const history = useHistory()

    return (
        <>
            <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", alignItems: "center" }}>
                <Box>
                    <img src={stripeImg} className={classes.paymentImages} />
                </Box>
                <Box>
                    <img src={paypalImg} className={classes.paymentImages} />
                </Box>
            </Box>
        </>
    )
}

export default PaymentMethods;