import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    USER_DATA,
    REGISTER_SUCCESS,
    UPDATE_USER,
    CHANGE_PASSWORD,
    FORGOT_RESPONSE,
    RESET_PASSWORD_RESPONSE
} from './types';

import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const login = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}users/login`,
        data,
    }
    let response = await apiCall(config, dispatch)
    if(response.data.status){
        dispatch({
            type: LOGIN_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: LOGIN_FAILED,
            payload: response
        })
    }
}

export const forgotPassword = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}users/forgot`,
        data,
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: FORGOT_RESPONSE,
        payload: response
    })
}

export const ResetPasswordWithOtp = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}users/reset_password`,
        data,
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: RESET_PASSWORD_RESPONSE,
        payload: response
    })
}

export const registerUser = (data) => async dispatch => {
    return new Promise( async (resolve, reject) => {
        let config = {
            method: 'post',
            headers: {},
            url: `${BASE_URL}users/register`,
            data
        }
        let response = await apiCall(config, dispatch)
        dispatch({
            type: REGISTER_SUCCESS,
            payload: response
        })
        resolve(response.data);
    })
}

export const getUserData = () => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}users/get`
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: USER_DATA,
        payload: response
    })
}

export const updateUserData = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}users/update`,
        data
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: UPDATE_USER,
        payload: response
    })
}

export const changePassword = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        url: `${BASE_URL}users/change_password`,
        data
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: CHANGE_PASSWORD,
        payload: response
    })
}

export const logout = () => async dispatch => {
    dispatch({
        type: LOGOUT,
        payload: ''
    })
}