
import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0.2rem",
        },
    },
    descriptionTitle: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "1rem"
    },
    firstHead: {
        fontWeight: 600,
        color: "#7400B8",
        fontSize: 28,
        marginRight: "0.5rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    secondHead: {
        fontWeight: 600,
        fontSize: 28,
        borderBottom: "1px solid #7400B8",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
}))

const PlayLottoOnline = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box className={classes.descriptionTitle}>
                <Typography className={classes.firstHead}>Play Lotto </Typography>
                <Typography className={classes.secondHead}>Online</Typography>
            </Box>
            <Box>
                <Typography>
                    Welcome to LottosOnline – the best place to play the world’s biggest lottery jackpots. In just a few clicks, you could actually win millions. It’s fun, simple and the fastest way to change your life forever. It doesn’t matter where you live, you can play the lottery online here in a few simple steps.
                </Typography>
                <ol start="1">
                    <li>Create your free LottosOnline account</li>
                    <li>Choose a lottery draw and select your lucky numbers</li>
                    <li>Confirm your entry</li>
                    <li>Wait for your lottery results and confirmation of your winnings</li>
                </ol>
            </Box>
            <Box>
                <Typography>LottosOnline is secure, safe and totally legal, buying your official lottery ticket entries from licensed retailers around the world. This is your big chance to win big money, which is why we are crystal clear and open at every step. We guarantee your winnings are paid out. And if you’ve got questions, our friendly team are always on hand.</Typography>
            </Box>
        </Box>
    )
}

export default PlayLottoOnline