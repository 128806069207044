import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Badge from "@material-ui/core/Badge";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ritmuLogoImg from '../../resources/images/ritmu-logo.png'
import defaultImg from "../../resources/images/defaultProfile.jpg"

import { showChangePasswordModal, showLoginModal, showRegisterModal, showUserSettingsModal, showMyBookingsModal } from '../../actions/modals'
import { getMyBookings } from '../../actions/bookings'
import { logout, getUserData } from '../../actions/auth'
import toast from '../../components/common/toast';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '7rem',
        padding: "0.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: '#511e70'
    },
    compLogo: {
        width: "4rem",
        overflow: "hidden"
    },
    profilePicture: {
        width: "2rem",
        borderRadius: '50px',
        overflow: "hidden"
    },
    loginButton: {
        backgroundColor: "white",
        marginRight: "5px",
        color: "#7400B8",
        fontWeight: 500,
        '&:hover': {
            backgroundColor: 'orange !important',
        },
    },
    navigationControls: {
        color: "white",
        cursor: "pointer"
    },
    logoIcons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        // marginRight: "2rem"
    },
    name: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontWeight: 600
    },
    redbar: {
        backgroundColor:"red",
        color:'white', 
        textAlign: "center", 
        padding:'5px', 
        fontSize:"0.875rem",
        fontWeight:600
    }
}));

const Header = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
        
    const dispatch = useDispatch()
    
    const is_authenticated = useSelector(state => state.auth.is_authenticated)
    const user_data = useSelector(state => state.auth.user_data)
    const myBookings = useSelector(state => state.bookings.myBookings)
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    useEffect(() => {
        if(localStorage.getItem('token')){
            dispatch(getUserData())
            dispatch(getMyBookings())
        }
    },[])

    const handleLogout = () => {
        dispatch(logout())
        setOpen(false);
    }

    return (
        <>
            { (is_authenticated) && (!user_data.is_email_verified) ?
                <div className={classes.redbar}>Email Address is not verified !!! Please Verify It</div>
            : null }
            <Box className={classes.root}>
                <Box className={classes.logoIcons} style={{ justifyContent: "normal", cursor: "pointer" }} >
                    <img src={ritmuLogoImg} className={classes.compLogo} />
                </Box>
                <Box style={{ display: "flex", flexDirection: 'row-reverse', alignItems: "center", flexWrap: "wrap" }}>
                    {!is_authenticated ?
                        <>
                            <Button className={classes.loginButton} onClick={() => dispatch(showRegisterModal(true))}>Sign up</Button>
                            <Button className={classes.loginButton} onClick={() => dispatch(showLoginModal(true))}>Login</Button>
                            <IconButton
                                className={classes.navigationControls}
                                onClick={() => dispatch(showLoginModal(true))}
                                aria-label="cart"
                                color="inherit">
                                <Badge
                                    badgeContent={0}
                                    color="error">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>
                        </> : 
                        <>
                            <IconButton
                                className={`${classes.navigationControls}`}
                                aria-label="cart"
                                color="inherit"
                                onClick={() => dispatch(showMyBookingsModal({show: true}))}
                                style={{marginRight:'20px', marginTop: "10px"}}>
                                <Badge
                                    badgeContent={myBookings.length}
                                    color="error">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>
                            <Box item xs={2} sm={2} md={2} className={classes.logoIcons} style={{ justifyContent: "normal", cursor: "pointer" }} ref={anchorRef} onClick={handleToggle}>
                                <img src={user_data.profile_pic?user_data.profile_pic:defaultImg} className={classes.profilePicture} />
                                <div style={{ marginLeft: '0.4rem' }}>
                                    <div className={classes.name}>{user_data && `Hi! ${user_data.fname} ${user_data.lname}`} <ArrowDropDownIcon /></div>
                                </div>
                            </Box>
                        </>
                    }
                </Box>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ marginTop: "1rem", zIndex: 1000 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={() => dispatch(showUserSettingsModal(true))}>Settings</MenuItem>
                                        <MenuItem onClick={() => dispatch(showChangePasswordModal(true))}>Change Password</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </>
    );
}
export default Header;