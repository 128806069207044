import {
    SUBMIT_CONTACT_FORM
} from './types';
import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const submitContactForm = (data) => async dispatch => {
    return new Promise( async (resolve, reject) => {
        let config = {
            method: 'post',
            headers: {},
            url: `${BASE_URL}contactus/add`,
            data,
        }
        let response = await apiCall(config, dispatch)
        dispatch({
            type: SUBMIT_CONTACT_FORM,
            payload: response
        })
        resolve(response.data);
    })
}