import {
    LOGIN_MODAL,
    REGISTER_MODAL,
    FORGOT_MODAL,
    RESET_PASSWORD_MODAL,
    BOOK_LOTTERY_MODAL,
    USERSETTINGS_MODAL,
    CHANGE_PASSWORD_MODAL,
    MY_BOOKINGS_LOTTERY_MODAL
} from './types';

export const showLoginModal = (show) => async dispatch => {
    dispatch({
        type: LOGIN_MODAL,
        payload: show
    })
}

export const showForgotModal = (show) => async dispatch => {
    dispatch({
        type: FORGOT_MODAL,
        payload: show
    })
}

export const showResetPasswordModal = (show) => async dispatch => {
    dispatch({
        type: RESET_PASSWORD_MODAL,
        payload: show
    })
}

export const showUserSettingsModal = (show) => async dispatch => {
    dispatch({
        type: USERSETTINGS_MODAL,
        payload: show
    })
}

export const showRegisterModal = (show) => async dispatch => {
    dispatch({
        type: REGISTER_MODAL,
        payload: show
    })
}

export const showChangePasswordModal = (show) => async dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_MODAL,
        payload: show
    })
}

export const showBookLotteryModal = (data) => async dispatch => {
    dispatch({
        type: BOOK_LOTTERY_MODAL,
        payload: data
    })
}

export const showMyBookingsModal = (data) => async dispatch => {
    dispatch({
        type: MY_BOOKINGS_LOTTERY_MODAL,
        payload: data
    })
}