import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { getLotteries } from "../../actions/lotteries"
import { IMG_URL } from "../../const"

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import TimerCont from '../../components/timeLeftCounter'
import { showBookLotteryModal, showLoginModal } from '../../actions/modals'

import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor: "black",
        height: '8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: "1px solid #CCCCCC"
    },
    cardHeaderHeading: {
        color: "white",
        fontSize: "1rem"
    },
    cardTitle: {
        fontSize: "1.5rem",
        textAlign: "center",
        fontWeight: 600,
    },
    cardDescription: {
        marginTop: "0.5rem",
        textAlign: "center",
        height:"60px"
    },
    buttonCont: {
        width: "100%",
        textAlign: "center"
    },
    bookNowButton: {
        backgroundColor: "#7400B8",
        color: "white",
        width: "100%",
        height: "2.5rem",
        marginTop: "2rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    disbookNowButton: {
        backgroundColor: "#bbb",
        color: "white",
        width: "100%",
        height: "2.5rem",
        marginTop: "2rem",
        '&:hover': {
            backgroundColor: '#ccc !important',
        },
    }
}));

const LotteryCard = () => {

    const classes = useStyles();
    const [swipper, setSwipper] = useState(null)

    const dispatch = useDispatch();

    const is_authenticated = useSelector(state => state.auth.is_authenticated)
    const lotteries = useSelector(state => state.lotteries.lotteries)
    
    SwiperCore.use([Autoplay, Navigation]);

    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    let availTimmings = []
    const [selectedDate, setselectedDate] = useState(new Date())

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
            console.log(dimensions.width)
        }
    })

    useEffect(() => {
        loadLotteries()
    }, [])

    const loadLotteries = () => {
        dispatch(getLotteries())
    }

    const getNearByTime = (timmings) => {
        for (let i = 0; i < timmings.length; i++) {
            let timming = timmings[i]
            let hours = timming.value.split(':')[0]
            let mins = timming.value.split(':')[1]
            let seconds = parseInt(hours * 60 * 60) + parseInt(mins * 60)
            let current_time = moment().unix()
            let lottery_time = moment(new Date().toDateString()).unix() + seconds
            if (current_time < lottery_time) {
                return lottery_time
            }
            if (i == timmings.length - 1) {
                let h = timmings[0].value.split(":")[0]
                let m = timmings[0].value.split(":")[1]
                let s = parseInt(h * 60 * 60) + parseInt(m * 60)
                return (moment(new Date().toDateString()).unix() + s) + 86400
            }
        }
    }

    const getlotteryStamp = (selectedTs, timeValue, closing_time) => {
        let h = timeValue.split(":")[0];
        let m = timeValue.split(":")[1];
        let secs = parseInt(h * 60 * 60) + parseInt(m * 60)
        return (selectedTs + secs) - (closing_time*60)
    }

    return (
        lotteries.status? 
        <Swiper
            spaceBetween={25}
            slidesPerView={dimensions.width<500?1:dimensions.width<1000?2:3}
            onSwiper={(swiper) => {
                setSwipper(swiper)
            }}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            onInit={(swiper) => {
                swiper.autoplay.start()
            }}>
            {lotteries.data && lotteries.data.map((lottery) =>
                <SwiperSlide
                    onMouseEnter={() => {
                        swipper.autoplay.stop()
                    }}
                    onMouseLeave={() => {
                        swipper.autoplay.start()
                    }}>
                    <Box style={{ padding: "2rem 0", }}>
                        <Box className={classes.cardHeader}>
                            <img src={IMG_URL + lottery.photo} style={{ height: "100%", width: "100%" }} />
                        </Box>
                        <Box style={{ padding: "1rem", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                            <Box>
                                <Typography className={classes.cardTitle}>{lottery.lottery_name}</Typography>
                                <Typography className={classes.cardDescription}>{lottery.short_desc}</Typography>
                                <Box style={{ marginTop: "1rem" }}>
                                    <TimerCont timmings={getNearByTime(lottery.timmings)} />
                                </Box>
                                <Box className={classes.buttonCont} id="extraNumber">
                                    {lottery && lottery.timmings && lottery.timmings.map((time) => (
                                        getlotteryStamp(moment(new Date(moment(selectedDate).format("YYYY/MM/DD"))).unix(), time.value, lottery.closing_time) > moment().unix()?
                                            availTimmings.push(time.value):'1'
                                    ))}
                                </Box>
                                <Button 
                                    disabled={availTimmings.length>0?false:true}
                                    className={availTimmings.length>0?classes.bookNowButton:classes.disbookNowButton} 
                                    onClick={() => is_authenticated ? dispatch(showBookLotteryModal({ show: true, lottery_data: lottery })) : dispatch(showLoginModal(true))}>
                                    {availTimmings.length>0?'Play Now':'Book Tomorrow'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </SwiperSlide>
            )}
        </Swiper>
        : null
    )
}
export default LotteryCard;