import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import { Box, Modal, Typography, Button, TextField } from '@material-ui/core';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import ritmuLogoImg from '../resources/images/ritmu-logo.png'
import { showUserSettingsModal } from "../actions/modals"
import { updateUserData } from "../actions/auth"
import CircularProgress from '@material-ui/core/CircularProgress';
import toast from "../components/common/toast"

var cc = require('currency-codes');
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "30%",
        margin: '4vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        fontSize: "1.2rem",
        fontWeight: "500",
        marginTop: "10px"
    },
    uploadButton: {
        height: "3rem",
        backgroundColor: "#7400B8",
        color: "white",
        font: 'normal normal medium 1.5rem/1.5rem Poppins',
        marginTop: "2rem",
        borderRadius: "0.7rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    modalbody: {
        padding: '1rem 2rem',
        backgroundColor: "white"
    },
    textField: {
        width: "48%",
    },
    loginFields: {
        marginBottom: "0.5rem"
    },
    loginLogo: {
        height: '5rem'
    },
    fname: {
        width: "48%"
    },
}));

const UserSettingsModal = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [country, setCountry] = useState('')
    const [currency, setCurrency] = useState('')
    const [paypalEmail, setPaypalEmail] = useState('')
    const [currencyCodes, setCurrencyCodes] = useState([])
    const countryOptions = useMemo(() => countryList().getData(), [])

    const dispatch = useDispatch()
    
    const userSettingsModalVisible = useSelector((state) => state.modals.userSettingsModalVisible)
    const userData = useSelector((state) => state.auth.user_data)

    useEffect(() => {
        let tmpArray = [];
        cc.codes().map(opt => {
            tmpArray.push({ value: opt, label: opt })
        })
        setCurrencyCodes(tmpArray)
    }, [cc])

    useEffect(() => {
        setFname(userData.fname)
        setLname(userData.lname)
        setCountry(userData.country)
        setCurrency(userData.currency)
        setPaypalEmail(userData.paypalEmail)
    }, [userData])

    const handleSubmit = async () => {
        if (fname === "" || country === "" || currency === "" || paypalEmail === "") {
            toast.error("Please fill all the required fields to continue")
            return false
        }
        setLoading(true)
        let userData = {
            fname,
            lname,
            country,
            currency,
            paypalEmail
        }
        dispatch(updateUserData(userData));
        setLoading(false)
        dispatch(showUserSettingsModal(false))
    }

    return (
        <Modal
            open={userSettingsModalVisible}
            onClose={() => dispatch(showUserSettingsModal(false))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.paper}
        >
            <Box>
                <Box className={classes.modalbody}>
                    <Box style={{ display: 'flex', justifyContent: "center", cursor: "pointer" }} >
                        <img src={ritmuLogoImg} className={classes.loginLogo} />
                    </Box>
                    <Typography className={`${classes.loginTitle}`}>Update Your Details</Typography>
                    <Box className={classes.loginFields}>
                        <TextField
                            id="outlined-password-input"
                            label="First Name"
                            type="text"
                            variant="outlined"
                            value={fname}
                            className={classes.fname}
                            style={{ marginRight: "2%" }}
                            onChange={(txt) => setFname(txt.target.value)}
                        />
                        <TextField
                            id="outlined-password-input"
                            label="Last Name"
                            type="text"
                            value={lname}
                            className={classes.fname}
                            variant="outlined"
                            style={{ marginLeft: "2%" }}
                            onChange={(txt) => setLname(txt.target.value)}
                        />
                    </Box>
                    <Box className={classes.loginFields}>
                        <Select options={countryOptions} value={country} onChange={(value) => setCountry(value)} placeholder='Select Country' />
                    </Box>
                    <Box className={classes.loginFields}>
                        <Select options={currencyCodes} value={currency} onChange={(value) => setCurrency(value)} placeholder='Select Currency' />
                    </Box>
                    <Box className={classes.loginFields}>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="Paypal Account"
                            type="email"
                            variant="outlined"
                            value={paypalEmail}
                            onChange={(txt) => setPaypalEmail(txt.target.value)}
                        />
                    </Box>
                    <Button
                        variant="raised"
                        component="span"
                        className={classes.uploadButton}
                        style={{ color: "white", width: "100%" }}
                        onClick={() => handleSubmit()}
                        disabled={loading ? true : false}
                    >
                        {loading ? <CircularProgress style={{ color: "white!important" }} size={25} /> : 'Update Details'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default UserSettingsModal