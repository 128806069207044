import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    USER_DATA,
    REGISTER_SUCCESS,
    UPDATE_USER,
    CHANGE_PASSWORD,
    FORGOT_RESPONSE,
    RESET_PASSWORD_RESPONSE
} from "../actions/types";

const initialState = {
    is_authenticated: false,
    token: '',
    user_data: {},
    loginMessage: null,
    forgotStatus: null,
    resetPasswordResponse: null
};

export default (state = initialState, action) => {
    
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.data.data.token)
            return {
                ...state,
                is_authenticated: true,
                token: payload.data.data.token
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loginMessage: payload.data.message
        };
        case FORGOT_RESPONSE:
            return {
                ...state,
                forgotStatus: payload.data.status
        };
        case RESET_PASSWORD_RESPONSE:
            return {
                ...state,
                resetPasswordResponse: payload.data
        };
        case REGISTER_SUCCESS:
            return {
                ...state
            };
        case USER_DATA:
            return {
                ...state,
                is_authenticated: true,
                user_data: payload.data.data
            };
        case UPDATE_USER:
            return {
                ...state,
                user_data: payload.data.data
            };
        case CHANGE_PASSWORD:
            return {
                ...state
            };
        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                user_data: {},
                is_authenticated: false,
                token: ''
            };
        default:
            return state;
    }
}
