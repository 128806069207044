import {
    LOGIN_MODAL,
    REGISTER_MODAL,
    FORGOT_MODAL,
    RESET_PASSWORD_MODAL,
    BOOK_LOTTERY_MODAL,
    USERSETTINGS_MODAL,
    CHANGE_PASSWORD_MODAL,
    SHOW_TOAST,
    MY_BOOKINGS_LOTTERY_MODAL
} from "../actions/types";

const initialState = {
    loginModalVisible: false,
    registerModalVisible: false,
    forgotModalVisible: false,
    resetPasswordModalVisible: false,
    bookLotteryModalVisible: false,
    userSettingsModalVisible: false,
    changePasswordModalVisible: false,
    myBookingsModalVisible:false,
    lottery_data: null,
    bookings_data: null,
    show: false,
    type: '',
    text: ''
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_MODAL:
            return {
                ...state,
                loginModalVisible: payload,
            };
        case REGISTER_MODAL:
            return {
                ...state,
                registerModalVisible: payload
            }
        case FORGOT_MODAL:
            return {
                ...state,
                forgotModalVisible: payload
            }
        case RESET_PASSWORD_MODAL:
            return {
                ...state,
                resetPasswordModalVisible: payload
            }
        case USERSETTINGS_MODAL:
            return {
                ...state,
                userSettingsModalVisible: payload
            }
        case CHANGE_PASSWORD_MODAL:
            return {
                ...state,
                changePasswordModalVisible: payload
            }
        case BOOK_LOTTERY_MODAL:
            return {
                ...state,
                bookLotteryModalVisible: payload.show,
                lottery_data: payload.lottery_data
            }
        case MY_BOOKINGS_LOTTERY_MODAL:
            return {
                ...state,
                myBookingsModalVisible: payload.show,
                bookings_data: payload.bookings_data
            }
        case SHOW_TOAST:
            return {
                ...state,
                show: payload.show,
                type: payload.type,
                text: payload.text
            }
        default:
            return state;
    }
}