import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import facebookIcon from '../../resources/images/facebook.png';
import InstagramIcon from '../../resources/images/instagram.png';
import twitterIcon from '../../resources/images/twitter.png';
import { showLoginModal, showRegisterModal } from "../../actions/modals"


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#511e70",
        padding: "2rem 2rem"
    },
    heading: {
        color: "white",
        fontSize: "1.2rem",
        fontWeight: "600"
    },
    subHeading: {
        fontSize: "1rem",
        color: "white",
        cursor: "pointer"
    },
    iconCont: {
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContents: "center"
        },
        [theme.breakpoints.down('xs')]: {
            display: "block",
        }
    },
    socialMediaIcons: {
        display: 'flex',
        flexWrap: "wrap"
    },
    icon: {
        padding: "1rem",
        filter: 'invert(1)'
    },
    tmp: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '1rem'
        }
    },
    Bottom_Strip: {
        backgroundColor: '#333',
        height: 40,
    }
}));

const Footer = (action) => {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const is_authenticated = useSelector(state => state.auth.is_authenticated)

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={6} sm={4} md={3} lg={3} >
                    <Typography onClick={() => action.scrollTo('home')} className={classes.heading}>Home</Typography>
                    <Typography onClick={() => action.scrollTo('how_to')} className={classes.subHeading}>How To Play</Typography>
                    <Typography onClick={() => action.scrollTo('play_online')} className={classes.subHeading}>Play Online</Typography>
                    <Typography onClick={() => action.scrollTo('draw_result')} className={classes.subHeading}>Draw Result</Typography>
                    <Typography onClick={() => action.scrollTo('live_tv')} className={classes.subHeading}>Live Tv</Typography>
                    <Typography onClick={() => action.scrollTo('contact_us')} className={classes.subHeading}>Contact Us</Typography>
                    <Typography onClick={() => action.scrollTo('blog')} className={classes.subHeading}>Blogs</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={3} >
                    <Typography className={classes.heading}>Help</Typography>
                    <Typography className={classes.subHeading}>FAQs</Typography>
                    {(!is_authenticated) &&
                        <>
                            <Typography onClick={() => dispatch(showLoginModal(true))} className={classes.subHeading}>Login</Typography>
                            <Typography onClick={() => dispatch(showRegisterModal(true))} className={classes.subHeading}>Open Free Account</Typography>
                        </>
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={3} className={classes.tmp}>
                    <Typography className={classes.heading}>More</Typography>
                    <Typography className={classes.subHeading}>Download Android App</Typography>
                    <Typography className={classes.subHeading}>Download iOS App</Typography>
                    <Typography className={classes.subHeading}>Privacy Policy</Typography>
                    <Typography className={classes.subHeading}>Terms & Conditions</Typography>
                </Grid>
                <Grid item xs={6} sm={12} md={3} lg={3} className={classes.iconCont}>
                    <Box className={classes.socialMediaIcons}>
                        <img src={facebookIcon} className={classes.icon} />
                        <img src={InstagramIcon} className={classes.icon} />
                        <img src={twitterIcon} className={classes.icon} />
                    </Box>
                </Grid>
            </Grid>
            <Grid className={classes.Bottom_Strip}>
                <Typography style={{ color: 'white', textAlign: "center", lineHeight: '40px' }}>Powered By Ritmu Developers</Typography>
            </Grid>
        </>
    )
}

export default Footer;