import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import LotteryCard from './lotteryCard'
import LotteryTabel from './lotteryTabel'
import LiveTV from './liveTV'
import PlayLottoOnline from './playLottoOnline'
import FeaturedArticle from './featuredArticles'
import PaymentMethods from './paymentMethods'
import DrawResult from './drawResultBox'
import ContactForm from './contact_form'
import TopNavBar from './navBar'
import Footer from './footer'
import { Grid } from '@material-ui/core';

import advertBannerImg from '../../resources/images/advert.jpg'
import ritmuBannerImg from '../../resources/images/ritmuBanner.jpg'
import appleBtn from '../../resources/images/apple.png'
import googleBtn from '../../resources/images/google.png'

import { getLotteries } from "../../actions/lotteries"

const useStyles = makeStyles((theme) => ({
    bannerRoot: {
        position: "relative"
    },
    banner: {
        width: "100%",
        [theme.breakpoints.down('md')]: {
            height: "max-content",
        },
        [theme.breakpoints.down('sm')]: {
            height: "max-content",
        },
        [theme.breakpoints.down('xs')]: {
            height: "max-content",
        }
    },
    bannerButton: {
        backgroundColor: "white",
        marginLeft: "2rem",
        color: "#7400B8",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: 'white !important',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "0.6rem"
        },
    },
    bannerContent: {
        position: 'absolute',
        bottom: '1rem',
        left: "1rem",
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    bannerTitle: {
        color: "white",
        fontSize: "1.7rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: "1rem",
        }
    },
    advertBanner: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}));

const DashBoard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    
    const lotteries = useSelector(state => state.lotteries.lotteries)

    useEffect(() => {
        loadLotteries()
    }, [])

    const loadLotteries = () => {
        dispatch(getLotteries())
    }

    const homeRef = useRef(null)
    const playOnlineRef = useRef(null)
    const howToPlayRef = useRef(null)
    const drawResultRef = useRef(null)
    const liveTvRef = useRef(null)
    const contactUsRef = useRef(null)
    const blogsRef = useRef(null)

    const scrollTo = (component) => {
        switch(component){
            case "home":
                homeRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "play_online":
                playOnlineRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "how_to":
                howToPlayRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "draw_result":
                drawResultRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "live_tv":
                liveTvRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "contact_us":
                contactUsRef.current.scrollIntoView({behavior: "smooth"})
                break;
            case "blog":
                blogsRef.current.scrollIntoView({behavior: "smooth"})
                break;
            default:
                history.push("/")
        }
    }

    return (
        <>
            <TopNavBar scrollTo={(component) => scrollTo(component)} />
            <Box>
                <Box ref={homeRef} container className={classes.bannerRoot}>
                    <Box className={classes.banner}>
                        <img src={ritmuBannerImg} className={"img-fluid"} />
                    </Box>
                    <Box className={classes.bannerContent}>
                        <Box style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                            <img src={appleBtn} style={{ marginRight: "1rem", marginBottom: "1rem", borderRadius: 5 }} />
                            <img src={googleBtn} style={{ marginRight: "1rem", marginBottom: "1rem", borderRadius: 5 }} />
                        </Box>
                    </Box>
                </Box>
                <Grid container ref={playOnlineRef} style={{marginLeft:'2%', width:'98%'}}>
                    <Grid item xs={12} sm={12} md={9} lg={9} >
                        <LotteryCard />
                        <LotteryTabel />
                    </Grid>
                    <Grid item xs={0} sm={0} md={3} className={classes.advertBanner}>
                        <Box style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <img src={advertBannerImg} className={'img-fluid'} />
                        </Box>
                    </Grid>
                </Grid>
                <Box ref={liveTvRef}>
                    <LiveTV />
                </Box>
                <Box style={{ marginBottom: "2rem", paddingLeft:'2rem', paddingRight:'2rem' }} ref={howToPlayRef}>
                    <PlayLottoOnline />
                </Box>
                <Divider />
                <Box ref={blogsRef}>
                    <FeaturedArticle name="Featured" />
                </Box>
                <Box ref={drawResultRef}>
                    <DrawResult data={lotteries.status?lotteries.data:[]} />
                </Box>
                <Box ref={contactUsRef}>
                    <ContactForm />
                </Box>
                <Box style={{background:"white"}}>
                    <PaymentMethods />
                </Box>
                <Footer scrollTo={(component) => scrollTo(component)} />
            </Box>
        </>
    );
}

export default DashBoard