import {
    GET_LOTTERIES
} from "../actions/types";

const initialState = {
    lotteries: {status: false}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_LOTTERIES:
            return {
                ...state,
                lotteries: payload.data
            };
        default:
            return state;
    }
}