import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { getLotteries } from "../../actions/lotteries"
import { IMG_URL } from "../../const"
import moment from "moment";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { showBookLotteryModal, showLoginModal } from '../../actions/modals'
import TimerCont from '../../components/timeLeftCounter'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#511e70',
        color: theme.palette.common.white,
        fontSize: 18,
    },
    body: {
        fontSize: 16,
        fontWeight: 600
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: "100%",
    },
    playNowButton: {
        backgroundColor: '#7400B8',
        marginTop: "0.3rem",
        color: "white",
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#673e80 !important',
        }
    },
    displayNowButton: {
        backgroundColor: '#bbb',
        marginTop: "0.3rem",
        color: "white",
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#ccc !important',
        }
    }
}));

const LotteryTabel = () => {
    const classes = useStyles();
    const is_authenticated = useSelector(state => state.auth.is_authenticated)
    const lotteries = useSelector(state => state.lotteries.lotteries)
    const dispatch = useDispatch();

    let availTimmings = []
    const [selectedDate, setselectedDate] = useState(new Date())

    useEffect(() => {
        loadLotteries()
    }, [])

    const loadLotteries = () => {
        dispatch(getLotteries())
    }

    const getNearByTime = (timmings) => {
        for (let i = 0; i < timmings.length; i++) {
            let timming = timmings[i]
            let hours = timming.value.split(':')[0]
            let mins = timming.value.split(':')[1]
            let seconds = parseInt(hours * 60 * 60) + parseInt(mins * 60)
            let current_time = moment().unix()
            let lottery_time = moment(new Date().toDateString()).unix() + seconds
            if (current_time < lottery_time) {
                return lottery_time
            }
            if (i == timmings.length - 1) {
                let h = timmings[0].value.split(":")[0]
                let m = timmings[0].value.split(":")[1]
                let s = parseInt(h * 60 * 60) + parseInt(m * 60)
                return (moment(new Date().toDateString()).unix() + s) + 86400
            }
        }
    }

    const getlotteryStamp = (selectedTs, timeValue, closing_time) => {
        let h = timeValue.split(":")[0];
        let m = timeValue.split(":")[1];
        let secs = parseInt(h * 60 * 60) + parseInt(m * 60)
        return (selectedTs + secs) - (closing_time*60)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Lottery Name</StyledTableCell>
                        <StyledTableCell align="center">Short Description</StyledTableCell>
                        <StyledTableCell align="center">Time Left To Play</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lotteries.status && lotteries.data.map((lottery, i) =>
                        <StyledTableRow key={lottery.lottery_name} style={{ backgroundColor: `${i % 2 ? "#dedede" : "white"}` }}>
                            <StyledTableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ marginRight: "1rem", width: "3.5rem", height: "2.5rem", borderRadius: "5rem", backgroundColor: "black" }}>
                                    <img src={IMG_URL + lottery.photo} style={{ width: "100%", height: "100%" }} />
                                </Box>
                                <Typography style={{ fontWeight: 600 }}>{lottery.lottery_name}</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center">{lottery.short_desc}</StyledTableCell>
                            <StyledTableCell align="center"><TimerCont timmings={getNearByTime(lottery.timmings)} /></StyledTableCell>
                            <div id="extraNumber">
                                {lottery && lottery.timmings && lottery.timmings.map((time) => (
                                    getlotteryStamp(moment(new Date(moment(selectedDate).format("YYYY/MM/DD"))).unix(), time.value, lottery.closing_time) > moment().unix()?
                                    availTimmings.push(time.value):'1'
                                ))}
                            </div>
                            <StyledTableCell align="center">
                                <Button
                                    disabled={availTimmings.length>0?false:true}
                                    className={availTimmings.length>0?classes.playNowButton:classes.displayNowButton} 
                                    onClick={() => is_authenticated ? dispatch(showBookLotteryModal({ show: true, lottery_data: lottery })) : dispatch(showLoginModal(true))}>
                                    {availTimmings.length>0?'Play Now':"Book Tomorrow"}
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LotteryTabel;