import {
    GET_ARTICLES,
    GET_ARTICLE_DETAIL
} from "../actions/types";

const initialState = {
    articles: [],
    articleData: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ARTICLES:
            return {
                ...state,
                articles: payload.data
            };
        case GET_ARTICLE_DETAIL:
            return {
                ...state,
                articleData: payload.data
            };
        default:
            return state;
    }
}