import {
    GET_LOTTERIES
} from './types';
import { BASE_URL } from "../const"
import { apiCall } from "../api";

export const getLotteries = (data) => async dispatch => {
    let config = {
        method: 'post',
        headers: {},
        url: `${BASE_URL}lotteries/get_all`,
        data,
    }
    let response = await apiCall(config, dispatch)
    dispatch({
        type: GET_LOTTERIES,
        payload: response
    })
}