import React,{ useState, useEffect } from 'react'
import Iframe from 'react-iframe'
import { useDispatch, useSelector } from "react-redux"
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ReactHlsPlayer from 'react-hls-player';
import { getDescriptions,getAllDescriptions } from "../../actions/descriptions"
import { BASE_URL } from "../../const"

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "3rem",
        marginTop: "3rem",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0.2rem",
        },
    },
    panelTitle: {
        textAlign: 'center',
        fontSize: '3rem',
        fontWeight: 600,
        color: '#7400B8',
        textDecoration: 'underline',
    },
    descriptionTitle: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "1rem",
        justifyContent: "center"
    },
    firstHead: {
        fontWeight: 600,
        color: "#7400B8",
        fontSize: 28,
        marginRight: "0.5rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    secondHead: {
        fontWeight: 600,
        fontSize: 28,
        borderBottom: "1px solid #7400B8",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },
    tvWindow: {
        padding: "1rem 4rem",
        width: "60%",
        [theme.breakpoints.down('md')]: {
            padding: "3rem 3rem",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "3rem 2rem",
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    iframe: {
        [theme.breakpoints.down('xs')]: {
            height: "200px !important"
        }
    }
}))

const LiveTV = () => {
    // const [descriptions, setDescriptions] = useState([])

    const [users, setUsers] = useState([]);

    const fetchData =async  () => {
        debugger
        const response= await fetch(`${BASE_URL}description/get_desc`)
        const data = await response.json()
        debugger
        setUsers(data)
        }
 
  
    useEffect(() => {
      fetchData()
    }, [users])


    // const descriptions = useSelector(state => state.lotteries.lotteries)
// var descriptions=[];
// const dispatch = useDispatch();
//     useEffect(() => {
//         loadDescriptions()
//     }, [])

//     const loadDescriptions = async() => {
//         // descriptions= await getAllDescriptions();
//         debugger
//         // descriptions =await getAllDescriptions();
//         dispatch(getAllDescriptions())
//         // let result = await getAllDescriptions()
//         // setDescriptions(result)
//         debugger
//     }





    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.descriptionTitle}>
                <Typography className={classes.firstHead}>Ritmu </Typography>
                <Typography className={classes.secondHead}>Live TV</Typography>
            </Box>
            <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box className={classes.tvWindow}>
                <ReactHlsPlayer
                    src="http://162.244.83.128:1935/live/livestream/playlist.m3u8"
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                />
                </Box>
            </Box>
            <Typography style={{ textAlign: "left", fontSize: 14, width: "100%", textAlign: "center" }}>
           <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          
            </Box>
            {users.status && users.data.map((lottery, i) =>
              <li>  
              {lottery.doc_data.BottomText}  
            </li>  
            )}     
               {/* { users.data[0].doc_data.BottomText} */}
                </Typography>
            <Divider style={{ marginTop: "2rem" }} />
        </Box>
    )
}

export default LiveTV;