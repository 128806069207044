import {
    CREATE_CUSTOMER_TOKEN,
    MAKE_PAYPAL_PAYMENT,
    CREATE_STRIPE_CARD_TOKEN,
    MAKE_STRIPE_PAYMENT,
    GET_MY_BOOKINGS
} from "../actions/types";

const initialState = {
    customerToken: null,
    paymentResponse: null,
    stripeCardToken: null,
    myBookings: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_CUSTOMER_TOKEN:
            return {
                ...state,
                customerToken: payload.data.data.token
            };
        case MAKE_PAYPAL_PAYMENT:
            return {
                ...state,
                paymentResponse: payload.data
            };
        case CREATE_STRIPE_CARD_TOKEN:
            return {
                ...state,
                stripeCardToken: payload.data
            };
        case MAKE_STRIPE_PAYMENT:
            return {
                ...state,
                paymentResponse: payload.data
            };
        case GET_MY_BOOKINGS:
            return {
                ...state,
                myBookings: payload.data.status?payload.data.data:[]
            };
        default:
            return state;
    }
}