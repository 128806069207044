import './App.css';
import React from 'react'
import theme from './theme'
import { ThemeProvider } from '@material-ui/core/styles'
import Main from './screens'
import store from './store';
import { Provider } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Main />
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;