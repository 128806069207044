import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import toast from "../components/common/toast"
import {
    Box,
    Modal,
    Typography,
    Button,
    TextField,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { login, getUserData } from "../actions/auth"
import { showForgotModal, showLoginModal, showRegisterModal, showResetPasswordModal } from "../actions/modals"
import ritmuLogoImg from '../resources/images/ritmu-logo.png'
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "30%",
        margin: '20vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },
    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        display: "flex",
        justifyContent: "center",
        fontSize: '1.2rem',
        alignItems: "center",
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    uploadButton: {
        height: "3rem",
        backgroundColor: "#7400B8",
        color: "white",
        font: 'normal normal medium 1.5rem/1.5rem Poppins',
        marginTop: "2rem",
        borderRadius: "0.5rem",
        '&:hover': {
            backgroundColor: '#7400B8 !important',
        },
    },
    modalbody: {
        padding: '1rem 2rem',
        backgroundColor: "white"
    },
    textField: {
        width: '100%',
    },
    loginFields: {
        marginBottom: "1rem"
    },
    footerContent: {
        textAlign: "center",
        marginTop: "5rem"
    },
    signUpOption: {
        width: "100%",
        color: "#7400B8",
        textAlign: "center",
        marginLeft: "0.4rem",
        cursor: "pointer"
    },
    loginLogo: {
        height: '5rem'
    },
    forgotLink: {
        marginTop:'1rem'
    }
}));

const LoginModal = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)
    
    const loginModalVisible = useSelector(state => state.modals.loginModalVisible)
    const loginMessage = useSelector(state => state.auth.loginMessage)
    const is_authenticated = useSelector(state => state.auth.is_authenticated)

    const handleLogin = async () => {
        if (email === "" || password === "") {
            toast.error("Please fill all the required feilds to proceed...")
            return false
        }
        setLoading(true);
        dispatch(login({ email, password }))
        setLoading(false);
        if(is_authenticated){
            dispatch(showLoginModal(false))
        }
    }

    useEffect(() =>{
        if(loginMessage){
            toast.error(loginMessage)
        }
    },[loginMessage])

    useEffect(() => {
        if (token && token.length) {
            dispatch(getUserData())
            dispatch(showLoginModal(false))
        }
    }, [token])

    const newUserSignUp = () => {
        dispatch(showResetPasswordModal(false))
        dispatch(showLoginModal(false))
        dispatch(showForgotModal(true))
        dispatch(showRegisterModal(true))
    }
    
    const forgotModal = () => {
        dispatch(showResetPasswordModal(false))
        dispatch(showRegisterModal(false))
        dispatch(showLoginModal(false))
        dispatch(showForgotModal(true))
    }

    return (
        <Modal
            open={loginModalVisible}
            onClose={() => dispatch(showLoginModal(false))}
            className={classes.paper}
        >
            <Box>
                <Box className={classes.modalbody}>
                    <Box style={{ display: 'flex', justifyContent: "center", cursor: "pointer" }} >
                        <img src={ritmuLogoImg} className={classes.loginLogo} />
                    </Box>
                    <Typography className={classes.loginTitle}>Login to your Account</Typography>
                    <Box className={classes.loginFields}>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="Login"
                            type="text"
                            autoComplete="current-password"
                            variant="outlined"
                            placeholder={"abc@xyz.com"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                    <Box className={classes.loginFields}>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                placeholder={"********"}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Box>
                    <Button
                        variant="raised"
                        component="span"
                        className={classes.uploadButton}
                        style={{ width: "100%" }}
                        onClick={() => handleLogin()}
                        disabled={loading ? true : false}
                    >
                        {loading ? <CircularProgress /> : "Login Now"}
                    </Button>
                    <Box className={classes.forgotLink}>
                        <Typography onClick={forgotModal}>Forgot Password ?</Typography>
                    </Box>
                    <Box className={classes.footerContent}>
                        <Typography>Not a member yet? <span className={classes.signUpOption} onClick={newUserSignUp}>Sign Up</span></Typography>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
export default LoginModal