import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Modal, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"

import { showBookLotteryModal } from "../actions/modals"
import { createCustomerToken, getMyBookings } from "../actions/bookings"

import stripeOptionImg from '../resources/images/stripeOption.png'
import paypalOptionImg from '../resources/images/paypalOption.png'
import toast from '../components/common/toast';

import PaypalUI from "./paypal"
import StripeUI from "./stripeform"

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "70%",
        margin: '4vh auto',
        zIndex: '999',
        height: 'fit-content',
        minHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            margin: 'auto 0'
        }
    },

    outerForm: {
        padding: '1rem'
    },

    loginTitle: {
        textAlign: "center",
        color: "#7400B8",
        justifyContent: "center",
        alignItems: "start",
        fontWeight: '600',
        fontSize: '1.5rem',
    },

    descriptionTitle: {
        display: "flex",
        justifyContent: "center",
    },

    shortDesc: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '13px',
        marginBottom: '1rem'
    },

    firstHead: {
        fontWeight: 500,
        color: "orange",
        fontSize: 24,
        marginRight: "0.5rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        }
    },

    checkBoxRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    checkBocIcon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 20px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },

    checkedIcon: {
        backgroundColor: 'white',
        width: "1.2rem",
        height: "1.2rem",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 19,
            height: 19,
            backgroundImage: 'radial-gradient(#7400B8,#7400B8 42%,transparent 50%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    payment_mode: {
        display: "flex",
        width: "100%",
        paddingLeft: "1rem",
        height: "3rem"
    },

    checkRoot: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    checkIcon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 20px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },

    checkedIconCheckBox: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    paymentOptions: {
        width: "8rem"
    },

    dated: {
        width: "max-content",
    }

}));

const BookLotteryModal = () => {
    const classes = useStyles();
    const [selectedDate, setselectedDate] = useState(new Date())
    const [timeSlot, setTimeSlot] = useState("")
    const [lotteryNumber, setLotteryNumber] = useState("")
    const [amount, setAmount] = useState(0)
    const [amountChance2, setAmountChance2] = useState(0)
    const [paymentMode, setPaymentMode] = useState(null)
    const [terms, setTerms] = useState(false)
    const [lotteryData, setLotteryData] = useState(null)
    const [bookings, setBookings] = useState([])
    const [firstWin, setFirstWin] = useState(0)
    const [secondWin, setSecondWin] = useState(0)
    let   [totalPayment, setTotalPayment] = useState(0)
    const [bookId, setBookId] = useState(null)
    const dispatch = useDispatch()
    const lottery_data = useSelector(state => state.modals.lottery_data)
    const user_data = useSelector(state => state.auth.user_data)
    const bookLotteryModalVisible = useSelector(state => state.modals.bookLotteryModalVisible)
    const customerToken = useSelector(state => state.bookings.customerToken)
    const payResponse = useSelector(state => state.bookings.paymentResponse)

    const selectPaymentMode = async (payment_mode) => {
        if (bookings.length <= 0) {
            toast.error("Please Select a Booking to Proceed")
            return false
        }
        if (!terms) {
            toast.error("Please read and accept our terms and conditions for better understanding...")
            return false
        }
        setPaymentMode(payment_mode)
        let data = {
            lottery_id: lottery_data._id,
            lottery_time: timeSlot,
            lottery_date: moment(selectedDate).format("YYYY/MM/DD"),
            bookings,
            payment_mode,
            totalPayment,
            currency: user_data.currency.label
        }
        setLotteryData(data);
        if (payment_mode === "paypal") {
            dispatch(createCustomerToken())
        }
    }

    useEffect(() => {
        if (payResponse) {
            console.log(payResponse)
            if (!payResponse.status) {
                toast.error(payResponse.message)
                return false
            }
            toast.success(payResponse.message);
            setTimeout(() => {
                dispatch(showBookLotteryModal(false))
                dispatch(getMyBookings())
                dispatch({
                    type: 'MAKE_STRIPE_PAYMENT',
                    payload: { data: null }
                })
                setPaymentMode("")
            }, 1000)
            return false
        }
    }, [payResponse])

    const getlotteryStamp = (selectedTs, timeValue, closing_time) => {
        let h = timeValue.split(":")[0];
        let m = timeValue.split(":")[1];
        let secs = parseInt(h * 60 * 60) + parseInt(m * 60)
        return (selectedTs + secs) - (closing_time*60)
    }

    const onAddAmount = (type, amount) => {
        if (lotteryNumber === "" || lotteryNumber.length > 4) {
            toast.error("Lottery Number Should Varies from 1 - 4 in Length")
            return false
        }
        if (type === "First") {
            if (lotteryNumber.length > 4) {
                toast.error("You can select Upto 4 Numbers only")
                return false
            }
            setAmount(amount)
            switch (lotteryNumber.length) {
                case 1:
                    setFirstWin(lottery_data.first_prize_multiplier_1 * amount)
                    break;
                case 2:
                    setFirstWin(lottery_data.first_prize_multiplier_2 * amount)
                    break;
                case 3:
                    setFirstWin(lottery_data.first_prize_multiplier_3 * amount)
                    break;
                case 4:
                    setFirstWin(lottery_data.first_prize_multiplier_4 * amount)
                    break;
            }
        }
        if (type === "Second") {
            setAmountChance2(amount)
            switch (lotteryNumber.length) {
                case 1:
                    setSecondWin(lottery_data.second_prize_multiplier_1 * amount)
                    break;
                case 2:
                    setSecondWin(lottery_data.second_prize_multiplier_2 * amount)
                    break;
                case 3:
                    setSecondWin(lottery_data.second_prize_multiplier_3 * amount)
                    break;
                case 4:
                    setSecondWin(lottery_data.second_prize_multiplier_4 * amount)
                    break;
            }
        }
    }

    const addToBookArray = () => {
        if (selectedDate == "" || timeSlot == "" || lotteryNumber == "" || amount == "") {
            toast.error("Please Fill all required feilds to proceed...")
            return false
        }
        if(amount<0){
            toast.error("Invalid Amount")
            return false
        }
        if (bookId !== null) {
            totalPayment -= parseFloat(bookings[bookId].amount) + parseFloat(bookings[bookId].amountChance2)
            bookings[bookId].lotteryNumber = lotteryNumber
            bookings[bookId].amount = amount
            bookings[bookId].amountChance2 = amountChance2
            totalPayment += parseFloat(amount) + parseFloat(amountChance2)
            setTotalPayment(totalPayment)
            setBookings([...bookings])
            setLotteryNumber("")
            setAmount(0)
            setAmountChance2(0)
            setFirstWin(0)
            setSecondWin(0)
            setBookId(null)
        } else {
            let bookingObj = {
                lotteryNumber,
                amount,
                amountChance2
            }
            bookings.push(bookingObj)
            totalPayment += parseFloat(amount) + parseFloat(amountChance2)
            setTotalPayment(totalPayment)
            setBookings([...bookings])
            setLotteryNumber("")
            setAmount(0)
            setAmountChance2(0)
            setFirstWin(0)
            setSecondWin(0)
        }
    }

    const editBooking = (i) => {
        setBookId(i)
        setLotteryNumber(bookings[i].lotteryNumber)
        setAmount(bookings[i].amount)
        setAmountChance2(bookings[i].amountChance2)
    }

    const deleteBooking = (i) => {
        totalPayment -= parseFloat(bookings[i].amount) + parseFloat(bookings[i].amountChance2)
        bookings.splice(i, 1)
        setBookings([...bookings])
        setTotalPayment(totalPayment)
    }

    const resetForm = () => {
        setLotteryNumber("")
        setAmount(0)
        setAmountChance2(0)
        setBookId(null)
        setFirstWin(0)
        setSecondWin(0)
    }

    const closeBookingForm = () => {
        setselectedDate(new Date())
        setTimeSlot("")
        setPaymentMode(null)
        setTerms(false)
        setLotteryData(null)
        setBookings([])
        setFirstWin(0)
        setSecondWin(0)
        setTotalPayment(0)
        setBookId(null)
        dispatch(showBookLotteryModal(false))
    }

    return (
        <Modal
            open={bookLotteryModalVisible}
            onClose={() => dispatch(showBookLotteryModal({ show: false, lottery_data: null }))}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.paper}>
            <div className={"row"} style={{ alignSelf: 'center'}}>
                <div className={"col-md-8 bg-white offset-md-2 p-3"} style={{height:'80vh', overflowY:"scroll" }}>
                    <Box className={classes.outerForm}>
                        <Box className={classes.formBody}>
                            <Typography className={classes.loginTitle}>Pick Up Number of Your Choice</Typography>
                        </Box>
                        <Box className={classes.descriptionTitle}>
                            <Typography className={classes.firstHead}>{lottery_data.lottery_name} </Typography>
                        </Box>
                        <Typography className={classes.shortDesc}>{lottery_data.short_desc}</Typography>
                        {paymentMode ?
                            paymentMode == "paypal" ?
                                <PaypalUI
                                    customerToken={customerToken}
                                    lotteryData={lotteryData}
                                    changePaymentMode={() => setPaymentMode(null)}
                                />
                                : paymentMode == "stripe" ?
                                    <StripeUI
                                        lotteryData={lotteryData}
                                        changePaymentMode={() => setPaymentMode(null)}
                                    />
                                    :
                                    null
                            :
                            <>
                                <div className={"row"}>
                                    <div className={"form-group col-6"}>
                                        <label>Date</label>
                                        <input disabled className={"form-control"} value={moment().format("LL")} />
                                    </div>
                                    <div className={"form-group col-6"}>
                                        <label>TimeSlot</label>
                                        <select onChange={(e) => setTimeSlot(e.target.value)} className={"form-control"} placeholder='Select Time Slot' >
                                            <option value={""}>Select Time Slot</option>
                                            {lottery_data && lottery_data.timmings && lottery_data.timmings.map((time) => (
                                                getlotteryStamp(moment(new Date(moment(selectedDate).format("YYYY/MM/DD"))).unix(), time.value, lottery_data.closing_time) > moment().unix() ?
                                                    <option value={time.value}>{time.value}</option>
                                                    : null
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col form-group"}>
                                        <label>Number</label>
                                        <input
                                            type={"tel"}
                                            maxLength={4}
                                            className={"form-control"}
                                            onChange={(e) => setLotteryNumber(e.target.value)}
                                            value={lotteryNumber}
                                            placeholder={"0000"}
                                        />
                                    </div>
                                    <div className={"col form-group"}>
                                        <label>First</label>
                                        <input
                                            type={"number"}
                                            min={0}
                                            className={"form-control"}
                                            onChange={(e) => onAddAmount("First", e.target.value)}
                                            value={amount}
                                            placeholder={"0"}
                                        />
                                    </div>
                                    {lottery_data.secondNumber ?
                                        <div className={"col form-group"}>
                                            <label>Second</label>
                                            <input
                                                type={"number"}
                                                min={0}
                                                className={"form-control"}
                                                onChange={(e) => onAddAmount("Second", e.target.value)}
                                                value={amountChance2}
                                                placeholder={"0"}
                                            />
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className={"row"}>
                                    <div className={"col text-left pr-0"}>
                                        <p className="font-weight-bold">You Will Win</p>
                                        <p>
                                            <span className="font-weight-bold">1st: </span>{parseFloat(firstWin).toFixed(2)} {user_data.currency.label}<br />
                                            {lottery_data.secondNumber ? (
                                                <>
                                                    <span className="font-weight-bold">2nd: </span>{parseFloat(secondWin).toFixed(2) + " " + user_data.currency.label}
                                                </>
                                            ) : null}
                                        </p>
                                    </div>
                                    <div className={"col text-right"}>
                                        <button className={"btn btn-secondry btn-sm"} onClick={() => resetForm()} >Clear</button>&nbsp;
                                <button className={"btn btn-success btn-sm"} onClick={() => addToBookArray()}>Book</button>
                                    </div>
                                </div>
                                <hr />
                                <div className={"col p-0"} style={{ overflow: "scroll" }}>
                                    <table className={"table table-bordered"}>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Number</th>
                                            <th>First</th>
                                            <th>Second</th>
                                            <th>Action</th>
                                        </tr>
                                        {bookings.map((booking, index) => (
                                            <tr>
                                                <td>{(index + 1)}</td>
                                                <td>{booking.lotteryNumber}</td>
                                                <td>{booking.amount}</td>
                                                <td>{booking.amountChance2}</td>
                                                <td>
                                                    <div className={"btn-group"}>
                                                        <button onClick={() => editBooking(index)} className={"btn btn-info btn-sm"}>Edit</button>&nbsp;
                                                        <button onClick={() => deleteBooking(index)} className={"btn btn-danger btn-sm"}>Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </table>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <p><span className="font-weight-bold">Total Amount to be Paid: </span>{parseFloat(totalPayment).toFixed(2)} {user_data.currency.label}</p>
                                    </div>
                                </div>
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                        className={classes.checkRoot}
                                        disableRipple
                                        color="default"
                                        checkedIcon={<span className={clsx(classes.checkIcon, classes.checkedIconCheckBox)} />}
                                        icon={<span className={classes.checkIcon} />}
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        onChange={(e) => setTerms(e.target.checked)}
                                    />
                                    <Typography style={{ fontSize: "0.75rem" }}>By clicking this, you agree to our <span style={{ textDecoration: "underline", fontWeight: 600 }}>Terms and Condition</span></Typography>
                                </Box>
                                <FormControl component="fieldset" className={classes.payment_mode}>
                                    <RadioGroup defaultValue="paypal" aria-label="payment_mode" name="customized-radios" style={{ flexDirection: "row", justifyContent: "center" }}>
                                        <FormControlLabel
                                            control={<></>}
                                            label="Pay Using"
                                        />
                                        <FormControlLabel
                                            value="paypal"
                                            control={<Radio
                                                className={classes.checkBoxRoot}
                                                checkedIcon={<span className={clsx(classes.checkBocIcon, classes.checkedIcon)} />}
                                                icon={<span className={classes.checkBocIcon} />}
                                            />}
                                            checked={paymentMode == 'paypal' ? true : false}
                                            onChange={(e) => selectPaymentMode(e.target.value)}
                                            label={<img alt="" src={paypalOptionImg} style={{ width: "100%", height: "100%" }} />}
                                            className={classes.paymentOptions}
                                        />
                                        <FormControlLabel
                                            value="stripe"
                                            control={<Radio
                                                className={classes.checkBoxRoot}
                                                checkedIcon={<span className={clsx(classes.checkBocIcon, classes.checkedIcon)} />}
                                                icon={<span className={classes.checkBocIcon} />}
                                            />}
                                            onChange={(e) => selectPaymentMode(e.target.value)}
                                            checked={paymentMode == 'stripe' ? true : false}
                                            label={<img alt="" src={stripeOptionImg} style={{ width: "75%", height: "100%" }} />}
                                            className={classes.paymentOptions}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <div className="col text-center">
                                    <button
                                        onClick={() => closeBookingForm()}
                                        className={"btn btn-secondry btn-sm mt-3"}>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }
                    </Box>
                </div>
            </div>
        </Modal >
    );
}

export default BookLotteryModal