import React from 'react'
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import moment from "moment"
import { IMG_URL } from "../const"

const useStyles = makeStyles((theme) => ({
    articleAboveCont: {
        width: "90%",
        borderRadius: "15px",
        backgroundColor: "white",
        boxShadow: "0 0px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 12px 0 rgba(0, 0, 0, 0.19)",
        "&:nth-child": {
            margin: 0
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.down('xs')]: {
            padding: "1rem",
            margin: "0.5rem"
        }
    },
    imageCont: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    image: {
        width: "100%", height: "10rem",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px"
    },
    leftHandSide: {
        padding: "1.5rem",
        [theme.breakpoints.down('xs')]: {
            // padding: 0
        }
    },
    articleTitle: {
        color: "#582178",
        fontSize: "1.25rem",
        fontWeight: 600,
        height:'65px'
    },
    articeTimeAuthorCont: {
        display: "flex",
        justifyContent: "space-between"
    },
    timeZone: {
        display: "flex",
        color: "#848484",
        fontStyle: "italic",
        fontSize: "0.9em"
    },
    description: {
        marginTop: "2rem",
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        height:'130px'
    },
    readMoreBtn: {
        color: "#8f32a3",
        cursor: "pointer"
    }
}))

const ArticleCard = ({data}) => {

    const classes = useStyles()
    const history = useHistory()

    return (
        <>
            <Box className={classes.articleAboveCont}>
                <Box className={classes.imageCont}>
                    <img alt="" src={`${IMG_URL}${data.image}`} className={classes.image} />
                </Box>
                <Box className={classes.leftHandSide}>
                    <Typography className={classes.articleTitle}>{data.title.substring(0,58)+"...."}</Typography>
                    <Box className={classes.articeTimeAuthorCont}>
                        <Typography className={classes.timeZone}>{moment(data.created_on*1000).format("LL")}</Typography>
                    </Box>
                    <Typography className={classes.description}>{data.short_desc}</Typography>
                    <Typography className={classes.readMoreBtn} onClick={() => history.push(`/blog/${data.url}`)} >Read More</Typography>
                </Box>
            </Box>
        </>
    )
}

export default ArticleCard;