import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoginModal, showRegisterModal, showForgotModal, showBookLotteryModal, showUserSettingsModal, showChangePasswordModal, showMyBookingsModal, showResetPasswordModal } from '../../actions/modals'
import LoginModal from '../../modals/loginModal'
import ForgotModal from '../../modals/forgotModal'
import ResetPasswordModal from '../../modals/resetPasswordModal'
import RegisterModal from '../../modals/registerModal'
import BookLotteryModal from '../../modals/bookLotteryModal'
import UserSettingsModal from '../../modals/userSettings'
import ChangePasswordModal from '../../modals/changePassword'
import MyBookingsModal from '../../modals/myBookingsModal'

const ModalConnecter = () => {
  const dispatch = useDispatch();
  const loginModalVisible = useSelector(state => state.modals.loginModalVisible);
  const forgotModalVisible = useSelector(state => state.modals.forgotModalVisible);
  const registerModalVisible = useSelector(state => state.modals.registerModalVisible);
  const resetPasswordModalVisible = useSelector(state => state.modals.resetPasswordModalVisible);
  const bookLotteryModalVisible = useSelector(state => state.modals.bookLotteryModalVisible);
  const userSettingsModalVisible = useSelector(state => state.modals.userSettingsModalVisible);
  const changePasswordModalVisible = useSelector(state => state.modals.changePasswordModalVisible);
  const myBookingsModalVisible = useSelector(state => state.modals.myBookingsModalVisible);
  
  const getLoginModalJsx = () => {
    return loginModalVisible ? (
      <LoginModal
        open={loginModalVisible}
        onClose={() => {
          dispatch(showLoginModal(false));
        }}
      />
    ) : null;
  };

  const getForgetModalJsx = () => {
    return forgotModalVisible ? (
      <ForgotModal
        open={forgotModalVisible}
        onClose={() => {
          dispatch(showForgotModal(false));
        }}
      />
    ) : null;
  };

  const getResetPasswordModalJsx = () => {
    return resetPasswordModalVisible ? (
      <ResetPasswordModal
        open={resetPasswordModalVisible}
        onClose={() => {
          dispatch(showResetPasswordModal(false));
        }}
      />
    ) : null;
  };

  const getRegisterModalJsx = () => {
    return registerModalVisible ? (
      <RegisterModal
        open={registerModalVisible}
        onClose={() => {
          dispatch(showRegisterModal(false))
        }}
      />
    ) : null;
  };

  const getBookLotteryModalJsx = () => {
    return bookLotteryModalVisible ? (
      <BookLotteryModal
        open={bookLotteryModalVisible}
        onClose={() => {
          dispatch(showBookLotteryModal({ show: false }))
        }}
      />
    ) : null;
  };

  const showUserSettingsModalJsx = () => {
    return userSettingsModalVisible ? (
      <UserSettingsModal
        open={userSettingsModalVisible}
        onClose={() => {
          dispatch(showUserSettingsModal(false))
        }}
      />
    ) : null;
  };

  const showChangePasswordModalJsx = () => {
    return changePasswordModalVisible ? (
      <ChangePasswordModal
        open={changePasswordModalVisible}
        onClose={() => {
          dispatch(showChangePasswordModal(false))
        }}
      />
    ) : null;
  };

  const showMyBookingsModalJsx = () => {
    return myBookingsModalVisible ? (
      <MyBookingsModal
        open={myBookingsModalVisible}
        onClose={() => {
          dispatch(showMyBookingsModal({ show: false }))
        }}
      />
    ) : null;
  };

  return [
    getLoginModalJsx(),
    getForgetModalJsx(),
    getResetPasswordModalJsx(),
    getRegisterModalJsx(),
    getBookLotteryModalJsx(),
    showUserSettingsModalJsx(),
    showChangePasswordModalJsx(),
    showMyBookingsModalJsx()
  ];
};

export default ModalConnecter;