import { combineReducers } from 'redux';
import auth from './auth';
import modals from './modals';
import lotteries from "./lotteries"
import bookings from "./bookings"
import contactus from "./contactus"
import draw_results from "./draw_results"
import articles from "./articles"
const appReducer = combineReducers({
  auth,
  modals,
  lotteries,
  bookings,
  contactus,
  articles,
  draw_results
});
const rootReducer = (state, action) => {
  return appReducer(state, action)
}
export default rootReducer;